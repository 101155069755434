import React from 'react';
import {
  Route,
  Routes,
} from "react-router-dom";
import { DiscussProvider } from './Discuss.context';
import DiscussDetailPage from './DiscussDetailPage';
import DiscussListPage from './DiscussListPage';
import DiscussWriteCommentPage from './DiscussWriteCommentPage';

export const DiscussConfig = () => {
  return (
    <Routes>
      <Route element={<DiscussProvider />}>
        <Route index={true} element={<DiscussListPage />} />
        <Route path="/detail/:id" element={<DiscussDetailPage />} />
        <Route path="/comment/:id" element={<DiscussWriteCommentPage />} />
      </Route>
    </Routes>
  )
}
export default DiscussConfig;