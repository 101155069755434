import { createContext, useContext } from 'react';
import { Outlet } from "react-router-dom";

export const DiscussContext = createContext({

});

export const DiscussProvider = (props) => {
  const { children } = props;

  return (
    <DiscussContext.Provider
      value={{

      }}
    >
      <Outlet/>
    </DiscussContext.Provider>
  );
};

export const DiscussConsumer = DiscussContext.Consumer;
export const useDiscuss = () => useContext(DiscussContext);