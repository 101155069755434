import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { Backdrop, CircularProgress, Box, Typography } from '@mui/material';

import MainHeader from '../../../common/MainHeader';
import { ColorButton } from '../../../common/ColorButton';
import SecureToggleTextField from '../../../common/SecureToggleTextField';
import { validatePasswordFormat } from '../../../common/Utils';
import { useForgetPassword } from '../ForgetPassword.context';
import URLRequest, { RequestType } from '../../../network/URLRequest';

const FIXED_TEXT = {
  forgetSetPassword: "重設密碼",
  forgetSetPasswordMsg: "設定新密碼",
  password: "密碼",
  passwordHint: "密碼需要長度8-12個字，並包含1個大階字母，1個小階字母",
  confirmPassword: "確認密碼",
  confirm: "確定",
  errPasswordEmpty: "密碼不能空白",
  errConfirmPasswordEmpty: "確認密碼不能空白",
  errPasswordLowerLimit: "密碼長度必須大於8個字",
  errPasswordUpperLimit: "密碼長度必須小於12個字",
  errPasswordCapitalLetter: "密碼必須包含1個大階字母",
  errPasswordSmallLetter: "密碼必須包含1個小階字母",
  errPasswordNotIdentical: "密碼與確認密碼不同",
}

function ForgetRequestSetPasswordPage() {
  const navigate = useNavigate();
  const { verifyCode, setVerifyCode, verifyToken, setVerifyToken} = useForgetPassword();

  const [loading, setLoading] = React.useState(false);

  const [inputPassword, setInputPassword] = useState('');
  const [inputConfirmPassword, setInputConfirmPassword] = useState('');
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    inputPassword: '',
    inputConfirmPassword: ''
  })

  function validate() {
    setIsFormInvalid(false)
    var errObj = {
      inputPassword: '',
      inputConfirmPassword: ''
    }
    if(!inputPassword || !inputConfirmPassword) {
      setIsFormInvalid(true)
      if (!inputPassword) {
        errObj.inputPassword = FIXED_TEXT.errPasswordEmpty
      }
      if (!inputConfirmPassword) {
        errObj.inputConfirmPassword = FIXED_TEXT.errConfirmPasswordEmpty
      } 
      console.log(errObj);
      setErrorMsg(errObj)
      return false
    }
    if(!validatePasswordFormat(inputPassword) || (inputPassword !== inputConfirmPassword)) {
      setIsFormInvalid(true)
      errObj.inputPassword = (
        <>
          {((inputPassword.length < 8) ? <div>{FIXED_TEXT.errPasswordLowerLimit}</div> : null)}
          {((inputPassword.length > 12) ? <div>{FIXED_TEXT.errPasswordUpperLimit}</div> : null)}
          {((!new RegExp("[A-Z]").test(inputPassword)) ? <div>{FIXED_TEXT.errPasswordCapitalLetter}</div> : null)}
          {((!new RegExp("[a-z]").test(inputPassword)) ? <div>{FIXED_TEXT.errPasswordSmallLetter}</div> : null)}
          {((inputPassword !== inputConfirmPassword) ? <div>{FIXED_TEXT.errPasswordNotIdentical}</div> : null)}
        </>
      )
      setErrorMsg(errObj)
      return false
    }
    setErrorMsg(errObj)
    return true
  }

  function handleForgetRequestSetPasswordSuccess(response) {
    setLoading(false)
    setVerifyCode('')
    setVerifyToken('')
    navigate('/login')
  }

  function handleForgetRequestSetPasswordFail(error) {
    setLoading(false)
  }

  function handleForgetRequestSetPassword() {
    if (validate()) {
      setLoading(true)
      URLRequest.request(RequestType.AuthForgetPasswordUpdate, [], {
        forgetPasswordCode: verifyCode,
        forgetPasswordToken: verifyToken,
        password: inputPassword,
        repeatPassword: inputConfirmPassword
      }, handleForgetRequestSetPasswordSuccess, handleForgetRequestSetPasswordFail)
    }  
  }

  return (
    <Box style={{
      height: '100%',
      display: 'flex', 
      flexDirection: 'column'}}>
      <MainHeader navTitle={FIXED_TEXT.forgetSetPassword} />

      <Box style={{margin: '24px', flex: 1, display: 'flex', flexDirection: 'column'}}>
        <Typography style={{margin : '12px 0'}}>
          {FIXED_TEXT.forgetSetPasswordMsg}
        </Typography>

        <SecureToggleTextField 
            error={isFormInvalid && (errorMsg.inputPassword !== '')} 
            helperText={errorMsg.inputPassword}
            id="inputPassword" 
            value={inputPassword} 
            onChange={text => setInputPassword(text.target.value)} 
            label={FIXED_TEXT.password}
            variant="outlined" />

        <span style={{padding: '16px 0'}}>{FIXED_TEXT.passwordHint}</span>

        <SecureToggleTextField 
            error={isFormInvalid && (errorMsg.inputConfirmPassword !== '')} 
            helperText={errorMsg.inputConfirmPassword}
            id="inputConfirmPassword" 
            value={inputConfirmPassword} 
            onChange={text => setInputConfirmPassword(text.target.value)} 
            label={FIXED_TEXT.confirmPassword}
            variant="outlined" />
      </Box>

      <Box style={{position: 'sticky', 
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: '16px 24px',
        backgroundColor: '#1D1D1D', 
        bottom: 0}}>

        <ColorButton 
          variant="outlined" 
          color="inherit"
          onClick={() => handleForgetRequestSetPassword()} 
          sx={{flex: 1, 
            padding: 1, 
            fontSize: 16, 
            textAlign: 'center'}}>
          {FIXED_TEXT.confirm}
        </ColorButton>
        
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}

export default ForgetRequestSetPasswordPage;