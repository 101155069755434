import React from "react";
import ReactDOM from "react-dom/client";
import { H } from "highlight.run";
import { ErrorBoundary } from "@highlight-run/react";

import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "./fonts/MazzardL-Bold.otf";
import "./fonts/MazzardL-BoldItalic.otf";
import "./fonts/MazzardL-Italic.otf";
import "./fonts/MazzardL-Regular.otf";
import App from "./App.js";

H.init("6glrn77g", {
  serviceName: "web",
  tracingOrigins: true,
  environment: process.env.NODE_ENV,
  networkRecording: {
    enabled: true,
    recordHeadersAndBody: true,
    urlBlocklist: [
      // insert full or partial urls that you don't want to record here
      // Out of the box, Highlight will not record these URLs (they can be safely removed):
      "https://www.googleapis.com/identitytoolkit",
      "https://securetoken.googleapis.com",
    ],
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
