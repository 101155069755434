import { useEffect, useState, useRef, createRef } from 'react';
import Grid from '@mui/material/Grid';
import { useHome } from '../../Home.context';
import { MaxidthImg, SelectCharacterBtn, NextBtn, InstructionText } from '../../common.style';

function Content(props) {
  const text = {
    pleaseOpenIG: '用IG開啓以繪畫你的匙仔',
    start: '開始'
  }
  const [imgPath, setImgPath] = useState('/images/JC_app_v1_output.gif');

  useEffect(() => {

  }, [imgPath]);

  return (
    <>
    <Grid container direction='column' justifyContent='center' alignItems='center'>
      <Grid item xs={6}><InstructionText>{text.pleaseOpenIG}</InstructionText></Grid>

      <Grid container style={{marginTop: '10px', maxWidth: '60vw', height: '55vh'}} justifyContent='center' alignItems='flex-end'>
        <MaxidthImg src={imgPath}/>
      </Grid>

      <Grid item xs={3} style={{marginTop: '10px'}}>
        <NextBtn href='/home/capture' variant='contained'>{text.start}</NextBtn>
      </Grid>
    </Grid>
    </>
  )
}

export default Content;