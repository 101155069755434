import React, { useState } from "react";
import { H } from "highlight.run";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {
  Backdrop,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useSignIn } from "react-auth-kit";

import jwtDecode from "jwt-decode";
import URLRequest, { RequestType } from "../../network/URLRequest";
import SecureToggleTextField from "../../common/SecureToggleTextField";

const FIXED_TEXT = {
  phone: "電話",
  password: "密碼",
  forgetPassword: "忘記密碼",
  login: "登入",
  errToken: "Unable to save access token!",
};

export default function LoginView() {
  const navigate = useNavigate();
  const signIn = useSignIn();

  const [inputPhone, setInputPhone] = useState("");
  const [inputPassword, setInputPassword] = useState("");

  const [loading, setLoading] = React.useState(false);

  function handleToForget() {
    navigate("/forgetPassword");
  }

  function handleLoginSuccess(response) {
    const secondsSinceEpoch = Math.round(new Date().getTime() / 1000);
    if (
      signIn({
        token: response.data.accessToken,
        expiresIn: response.data.accessTokenExpiryDate - secondsSinceEpoch,
        authState: jwtDecode(response.data.accessToken),
        tokenType: "Bearer",
        refreshToken: response.data.refreshToken, // Only if you are using refreshToken feature
        refreshTokenExpireIn:
          response.data.refreshTokenExpiryDate - secondsSinceEpoch, // Only if you are using refreshToken feature
      })
    ) {
      localStorage.setItem("authHeader", "Bearer " + response.data.accessToken);
      H.identify(inputPhone);
      setLoading(false);
      navigate("/member/discuss");
    } else {
      // cannot save the token for some reason, Throw error
      setLoading(false);
      alert(FIXED_TEXT.errToken);
    }
  }

  function handleLoginFail(error) {
    setLoading(false);
  }

  function handleLogin() {
    setLoading(true);

    URLRequest.request(
      RequestType.AuthLogin,
      [],
      {
        phone: inputPhone,
        password: inputPassword,
      },
      handleLoginSuccess,
      handleLoginFail
    );
  }

  return (
    <>
      <div className="container">
        <AppBar position="static">
          <Toolbar className="register-header">
            <img
              src={"/images/img_logo_white.png"}
              alt="logo"
              width={64}
              height={64}
              className="header-logo"
            />
          </Toolbar>
        </AppBar>
        <div className="register-body">
          <TextField
            id="inputPhone"
            value={inputPhone}
            onChange={(text) => setInputPhone(text.target.value)}
            label={FIXED_TEXT.phone}
            type="tel"
            variant="outlined"
          />
          <SecureToggleTextField
            id="inputPassword"
            value={inputPassword}
            onChange={(text) => setInputPassword(text.target.value)}
            label={FIXED_TEXT.password}
            variant="outlined"
          />
          <Button style={{ width: "100%" }} onClick={handleToForget}>
            {FIXED_TEXT.forgetPassword}
          </Button>
        </div>
        <div className="register-footer">
          <Button
            onClick={() => handleLogin()}
            disableRipple
            className="register-button"
          >
            <div className="register-button-text">{FIXED_TEXT.login}</div>
          </Button>
        </div>
      </div>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
