import React from 'react';
import { CaptureSiJaiProvider } from "./components/CaptureSiJai.context";
import Content from "./components/Content"

function CaptureSiJai(){
  return (  
    <CaptureSiJaiProvider>
      <Content/>
    </CaptureSiJaiProvider>
  )
}

export default CaptureSiJai;