import { createContext, useState, useContext } from 'react';
import { Outlet } from "react-router-dom";

export const HomeContext = createContext({
  activeIndex: 1,
  setActiveIndex: () => {},
  setActiveIndexToLocalStorage: () => {},
});

export const HomeProvider = (props) => {
  const { children } = props;
  const [activeIndex, setActiveIndex] = useState(localStorage.getItem('activeIndex')?localStorage.getItem('activeIndex'):1);
  
  const setActiveIndexToLocalStorage = (index) => {
    localStorage.setItem('activeIndex', index);
    setActiveIndex(index);
  }


  return (
    <HomeContext.Provider
      value={{
        activeIndex,
        setActiveIndexToLocalStorage,
      }}
    >
      <Outlet/>
    </HomeContext.Provider>
  );
};

export const HomeConsumer = HomeContext.Consumer;
export const useHome = () => useContext(HomeContext);