import moment from "moment";

const FIXED_TEXT = {
  month: '月',
  day: '日',
}

export const eventDateTimeFormat = (from) => {
  let date = moment(from).format('MM'+FIXED_TEXT.month+'DD'+FIXED_TEXT.day+' HH:mm');
  return date
}

export const validatePasswordFormat = (password) => {
  if ((password.length < 8) || (password.length > 12)) {
    return false;
  }

  let pwdCheck = 0;
  let validateRegex = ["[A-Z]", "[a-z]"];
  validateRegex.forEach((regex, i) => {
    if (new RegExp(regex).test(password)) {
      pwdCheck += 1;
    }
  });
  return (pwdCheck >= validateRegex.length)
}