import { createContext, useState, useContext } from 'react';

export const CaptureSiJaiContext = createContext({
    
});

export const CaptureSiJaiProvider = (props) => {
  const { children } = props;
  

  return (
    <CaptureSiJaiContext.Provider
      value={{
        
      }}
    >
      {children}
    </CaptureSiJaiContext.Provider>
  );
};


export const CaptureSiJaiConsumer = CaptureSiJaiContext.Consumer;
export const useCaptureSiJai = () => useContext(CaptureSiJaiContext);