import { createContext, useState, useContext } from 'react';

export const SelectSiJaiContext = createContext({
    
});

export const SelectSiJaiProvider = (props) => {
  const { children } = props;
  

  return (
    <SelectSiJaiContext.Provider
      value={{
        
      }}
    >
      {children}
    </SelectSiJaiContext.Provider>
  );
};


export const SelectSiJaiConsumer = SelectSiJaiContext.Consumer;
export const useSelectSiJai = () => useContext(SelectSiJaiContext);