import React from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function SecureToggleTextField(props) {
  const [show, setShow] = React.useState(false);
  const handleClickShow = () => setShow((show) => !show);
  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  return (
    <TextField 
      type={show ? 'text' : 'password'}
      InputProps={{
        endAdornment:
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShow}
              onMouseDown={handleMouseDown}
              edge="end"
            >
              {show ? <VisibilityOff style={{color: '#fff'}} /> : <Visibility style={{color: '#fff'}} />}
            </IconButton>
          </InputAdornment>
        }}
      {...props}
    />
  )
}

export default SecureToggleTextField;