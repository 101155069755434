import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { BlackColorButton } from "../../common/ColorButton";

const FIXED_TEXT = {
  confirm: "確定"
}

const DialogContext = React.createContext({
  openDialog: () => {},
  openMessageDialog: () => {},
  closeDialog: () => {}
});

export default function DialogProvider({ children }) {
  const [dialogs, setDialogs] = React.useState([]);

  const createDialog = (option) => {
    const dialog = { ...option, open: true };
    setDialogs((dialogs) => [...dialogs, dialog]);
  };

  const createMessageDialog = (title) => {
    console.log(title)
    createDialog({
      children: (
        <>
          <DialogContent 
            id="alert-dialog-title" 
            style={{fontSize: '20pt', paddingTop: '48px', textAlign: 'center'}}>
            <Typography
              variant="body1"
              style={{whiteSpace: 'pre-line', fontSize: '16pt ', color: '#ff3f00'}}>
            {title.replaceAll('"', '')}
            </Typography>
          </DialogContent>
          <DialogActions>
            <BlackColorButton 
              onClick={() => closeDialog()} 
              sx={{flex: 1, 
                margin: "24px", 
                padding: 1,
                fontSize: 16, 
                color: '#fff',
                backgroundColor: '#000',
                textAlign: 'center'}}>
              {FIXED_TEXT.confirm}
            </BlackColorButton>
          </DialogActions>
        </>
      )
    })
  }
  
  const closeDialog = () => {
    setDialogs((dialogs) => {
      const latestDialog = dialogs.pop();
      if (!latestDialog) return dialogs;
      if (latestDialog.onClose) latestDialog.onClose();
      return [...dialogs].concat({ ...latestDialog, open: false });
    });
  };

  return (
    <DialogContext.Provider value={{
        openDialog: createDialog, 
        openMessageDialog: createMessageDialog,
        closeDialog: closeDialog
    }}>
      {children}
      {dialogs.map((dialog, i) => {
        const { onClose, ...dialogParams } = dialog;
        const handleKill = () => {
          if (dialog.onExited) dialog.onExited();
          setDialogs((dialogs) => dialogs.slice(0, dialogs.length - 1));
        };

        return (
          <DialogContainer
            key={i}
            onClose={closeDialog}
            onKill={handleKill}
            {...dialogParams}
          />
        );
      })}
    </DialogContext.Provider>
  );
}

function DialogContainer(props) {
  const { children, open, onClose } = props;

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xl">
      {children}
    </Dialog>
  );
}

export const useAlertDialog = () => React.useContext(DialogContext);
