import jwtDecode from "jwt-decode";
import moment from "moment";
import { createRefresh } from "react-auth-kit";
import URLRequest, { RequestType } from "./URLRequest";

const RefreshApi = createRefresh({
  interval: 720,   // Refresh the token in every n minutes
  refreshApiCallback: (
    {
      authToken,
      authTokenExpireAt,
      refreshToken,
      refreshTokenExpiresAt,
      authUserState
    }) => {
      
    URLRequest.request(RequestType.AuthRefresh, [], {
      refreshToken: refreshToken
    }, function(response) {
      const secondsSinceEpoch = Math.round(new Date().getTime() / 1000)

      localStorage.setItem('authHeader', "Bearer "+response.data.accessToken)

      return {
        isSuccess: true,  // For successful network request isSuccess is true
        // You can also add new refresh token ad new user state
        newAuthToken: response.data.accessToken,
        newAuthTokenExpireIn: response.data.accessTokenExpiryDate - secondsSinceEpoch,
        newRefreshToken: response.data.refreshToken,                    // Only if you are using refreshToken feature
        newRefreshTokenExpiresIn: response.data.refreshTokenExpiryDate - secondsSinceEpoch,     // Only if you are using refreshToken feature
        authState: jwtDecode(response.data.accessToken),
      }
    }, function(error) {
      return{
        isSuccess:false // For unsuccessful network request isSuccess is false
      }
    })
  }
})

export default RefreshApi;