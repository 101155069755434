import { useEffect, useState, useRef, createRef } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { useHome } from '../../Home.context';
import { MaxidthImg, SelectCharacterBtn, NextBtn } from '../../common.style';

export const Footer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    position: 'fixed',
    bottom: 0,
    left: 0,

    paddingTop: '10px',
    width: '100%',
    height: '7vh',

    backgroundColor: '#000',
    color: '#fff',

    textAlign: 'center',
    whiteSpace: 'pre-line',
  },
}));

function Content(props) {
  const text = {
    footerInstruction: '長按儲存圖片 用IG開啓以繪畫你的匙仔\n TAG @not-a-lone 分享給我們',
    start: '開始'
  }
  const [imgPath, setImgPath] = useState('/images/img_character_1.png');
  const { activeIndex } = useHome();

  useEffect(() => {
    const prefix = '/images/img_saved_character';
    const postfix = '.png';
    setImgPath(prefix + "_" + activeIndex + postfix);
  }, []);

  return (
    <>
    <Grid container direction='column' justifyContent='center' alignItems='center'>
      <Grid container style={{maxWidth: '90vw', height: '60vh'}} justifyContent='center' alignItems='flex-end'>
        <MaxidthImg src={imgPath}/>
      </Grid>
      <Grid item xs={3} style={{marginBottom: '7vh'}}>
        <NextBtn href='/member/discuss' variant='contained'>{text.start}</NextBtn>
      </Grid>
    </Grid>
    <Footer>{text.footerInstruction}</Footer>
    </>
  )
}

export default Content;