import React from 'react';
import { SiJaiDemoProvider } from "./components/SiJaiDemo.context";
import Content from "./components/Content"

function SiJaiDemo(){
  return (
    <SiJaiDemoProvider>
      <Content/>
    </SiJaiDemoProvider>
  )
}

export default SiJaiDemo;