import { createContext, useContext } from 'react';
import { Outlet } from "react-router-dom";

export const AccountContext = createContext({

});

export const AccountProvider = (props) => {
  const { children } = props;

  return (
    <AccountContext.Provider
      value={{

      }}
    >
      <Outlet/>
    </AccountContext.Provider>
  );
};

export const AccountConsumer = AccountContext.Consumer;
export const useAccount = () => useContext(AccountContext);