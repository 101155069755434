import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { Backdrop, CircularProgress } from '@mui/material';
import { useSignIn } from 'react-auth-kit';
import jwtDecode from 'jwt-decode';
import URLRequest, { RequestType } from '../../network/URLRequest';

import { validatePasswordFormat } from '../../common/Utils';
import SecureToggleTextField from '../../common/SecureToggleTextField';

const FIXED_TEXT = {
  name: "姓名",
  phone: "電話",
  email: "電郵地址",
  igName: "IG 名稱",
  password: "密碼",
  passwordHint: "密碼需要長度8-12個字，並包含1個大階字母，1個小階字母",
  confirmPassword: "確認密碼",
  register: "注冊",
  errToken: "Unable to save access token!",
  errNameEmpty: "姓名不能空白",
  errPhoneEmpty: "電話不能空白",
  errEmailEmpty: "電郵地址不能空白",
  errIgNameEmpty: "IG 名稱不能空白",
  errPasswordEmpty: "密碼不能空白",
  errConfirmPasswordEmpty: "確認密碼不能空白",
  errPasswordLowerLimit: "密碼長度必須大於8個字",
  errPasswordUpperLimit: "密碼長度必須小於12個字",
  errPasswordCapitalLetter: "密碼必須包含1個大階字母",
  errPasswordSmallLetter: "密碼必須包含1個小階字母",
  errPasswordNotIdentical: "密碼與確認密碼不同"
}

export default function RegisterView() {
  const navigate = useNavigate();
  const signIn = useSignIn()
  const codeValidate = useRef(false);

  const { code } = useParams();
  const [inputName, setInputName] = useState('');
  const [inputPhone, setInputPhone] = useState('');
  const [inputEmail, setInputEmail] = useState('');
  const [inputIgName, setVig] = useState('');
  const [inputPassword, setInputPassword] = useState('');
  const [inputConfirmPassword, setInputConfirmPassword] = useState('');
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    inputName: '',
    inputPhone: '',
    inputEmail: '',
    inputIgName: '',
    inputPassword: '',
    inputConfirmPassword: ''
  })

  const [loading, setLoading] = React.useState(true);

  const validatePromoCode = useCallback(() => {
    if (codeValidate.current) return;
    codeValidate.current = true
    URLRequest.request(RequestType.AuthPromoCodeValidate, [code], {},
    function (response) {
      setLoading(false);
    }, function (error) {
      setLoading(false);
      navigate("/error");
    });
  }, [code, navigate]);

  useEffect(() => {
    validatePromoCode()
  }, [validatePromoCode]);

  function handleRegister() {
    if(validate()) {
      requestRegister()
    }
  }

  function handleRegisterSuccess(response) {
    const secondsSinceEpoch = Math.round(new Date().getTime() / 1000)
    if(signIn(
      {
        token: response.data.accessToken,
        expiresIn: response.data.accessTokenExpiryDate - secondsSinceEpoch,
        authState: jwtDecode(response.data.accessToken),
        tokenType: "Bearer",
        refreshToken: response.data.refreshToken,                    // Only if you are using refreshToken feature
        refreshTokenExpireIn: response.data.refreshTokenExpiryDate - secondsSinceEpoch     // Only if you are using refreshToken feature
      }
    )){
      // Redirect or do-something
      localStorage.setItem('authHeader', "Bearer "+response.data.accessToken)
      setLoading(false);
      navigate("/home/select");
    } else {
      alert("Unable to save access token!")
    }
  }

  function handleRegisterFail(error) {
    setLoading(false)
  }

  function validate() {
    setIsFormInvalid(false)
    var errObj = {
      inputName: '',
      inputPhone: '',
      inputEmail: '',
      inputIgName: '',
      inputPassword: '',
      inputConfirmPassword: ''
    }
    if(!inputName || !inputPhone || !inputEmail || !inputIgName || !inputPassword || !inputConfirmPassword) {
      setIsFormInvalid(true)
      if (!inputName) {
        errObj.inputName = FIXED_TEXT.errNameEmpty
      }
      if (!inputPhone) {
        errObj.inputPhone = FIXED_TEXT.errPhoneEmpty
      }
      if (!inputEmail) {
        errObj.inputEmail = FIXED_TEXT.errEmailEmpty
      }
      if (!inputIgName) {
        errObj.inputIgName = FIXED_TEXT.errIgNameEmpty
      }
      if (!inputPassword) {
        errObj.inputPassword = FIXED_TEXT.errPasswordEmpty
      }
      if (!inputConfirmPassword) {
        errObj.inputConfirmPassword = FIXED_TEXT.errConfirmPasswordEmpty
      } 
      console.log(errObj);
      setErrorMsg(errObj)
      return false
    }
    if(!validatePasswordFormat(inputPassword) || (inputPassword !== inputConfirmPassword)) {
      setIsFormInvalid(true)
      errObj.inputPassword = (
        <div>
          {((inputPassword.length < 8) ? <div>{FIXED_TEXT.errPasswordLowerLimit}</div> : null)}
          {((inputPassword.length > 12) ? <div>{FIXED_TEXT.errPasswordUpperLimit}</div> : null)}
          {((!new RegExp("[A-Z]").test(inputPassword)) ? <div>{FIXED_TEXT.errPasswordCapitalLetter}</div> : null)}
          {((!new RegExp("[a-z]").test(inputPassword)) ? <div>{FIXED_TEXT.errPasswordSmallLetter}</div> : null)}
          {((inputPassword !== inputConfirmPassword) ? <div>{FIXED_TEXT.errPasswordNotIdentical}</div> : null)}
        </div>
      )
      console.log(errObj);
      setErrorMsg(errObj)
      return false
    }
    setErrorMsg(errObj)
    return true
  }

  function requestRegister() {
    setLoading(true)
    URLRequest.request(RequestType.AuthRegister, [code], {
      phone: inputPhone,
      email: inputEmail,
      instagram: inputIgName,
      displayName: inputName,
      repeatPassword: inputConfirmPassword,
      password: inputPassword
    }, handleRegisterSuccess, handleRegisterFail)
  }

  return (
    <>
      <div className='container'>
        <AppBar position="static">
          <Toolbar className="register-header" >
            <img src={'/images/img_logo_white.png'} alt='logo' width={64} height={64} className='header-logo' />
          </Toolbar>
        </AppBar>
        <div className='register-body'>
          <TextField 
            error={isFormInvalid && (errorMsg.inputName !== '')}
            helperText={errorMsg.inputName}
            id="inputName" value={inputName} 
            onChange={text => setInputName(text.target.value)} 
            label={FIXED_TEXT.name} type='text' variant="outlined" />
          <TextField 
            error={isFormInvalid && (errorMsg.inputPhone !== '')}
            helperText={errorMsg.inputPhone}
            id="inputPhone" value={inputPhone}
            onChange={text => setInputPhone(text.target.value)}
            label={FIXED_TEXT.phone} type='tel' variant="outlined" />
          <TextField 
            error={isFormInvalid && (errorMsg.inputEmail !== '')}
            helperText={errorMsg.inputEmail}
            id="inputEmail" value={inputEmail}
            onChange={text => setInputEmail(text.target.value)}
            label={FIXED_TEXT.email} type='email' variant="outlined" />
          <TextField 
            error={isFormInvalid && (errorMsg.inputIgName !== '')} 
            helperText={errorMsg.inputIgName}
            id="inputIgName" value={inputIgName} 
            onChange={text => setVig(text.target.value)} 
            label={FIXED_TEXT.igName} type='text' variant="outlined" />
          <SecureToggleTextField 
            error={isFormInvalid && (errorMsg.inputPassword !== '')} 
            helperText={errorMsg.inputPassword}
            id="inputPassword" 
            value={inputPassword} 
            onChange={text => setInputPassword(text.target.value)} 
            label={FIXED_TEXT.password}
            variant="outlined" />
          <span>{FIXED_TEXT.passwordHint}</span>
          <SecureToggleTextField 
            error={isFormInvalid && (errorMsg.inputConfirmPassword !== '')} 
            helperText={errorMsg.inputConfirmPassword}
            id="inputConfirmPassword" 
            value={inputConfirmPassword} 
            onChange={text => setInputConfirmPassword(text.target.value)} 
            label={FIXED_TEXT.confirmPassword} 
            variant="outlined" />
        </div>
        <div className='register-footer'>
          <Button onClick={() => handleRegister()} disableRipple className='register-button'>
            <div className='register-button-text'>{FIXED_TEXT.register}</div>
          </Button>
        </div>
      </div>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
