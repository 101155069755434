import React from 'react';
import { HomeProvider } from './Home.context';
import SelectSiJai from './SelectSiJai/index';
import SiJaiDemo from './SiJaiDemo/index';
import CaptureSiJai from './CaptureSiJai';


import {
    Route,
    Routes,
  } from "react-router-dom";


export const HomeConfig = () => {
  return (
    <Routes>
      <Route element={<HomeProvider/>}>
        <Route path="/select" element={<SelectSiJai />}/>
        <Route path="/demo" element={<SiJaiDemo />}/>
        <Route path="/capture" element={<CaptureSiJai />}/>
      </Route>
    </Routes>
  )
}
export default HomeConfig;