import { Box, Button, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import { ColorButton } from "../../../common/ColorButton";
import MainHeader from "../../../common/MainHeader"
import URLRequest, { RequestType } from "../../../network/URLRequest";

const FIXED_TEXT = {
  discuss: "討論",
  discussEndTime: "完結日期：",
  emptyMsg: "請踴躍發表你的意見",
  writeComment: "發表意見",
  doubleLike: "你只能讚一個意見。"
}

const STATICS = {
  pageSize: 20
}

function DiscussDetailPage() {
  let navigate = useNavigate();
  let { id } = useParams();
  let [topicItem, setTopicItem] = useState({});
  let [commentList, setCommentList] = useState([]);
  let [liked, setLiked] = useState(false);

  const [currentPage, setCurrentPage] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [hasNext, setHasNext] = useState(true);

  useEffect(() => {
    getItem()
    getCommentList(0)
  }, []);

  function getItem() {
    URLRequest.request(RequestType.DiscussTopicOne, 
      [id], 
      {},
      function (response) {
        setTopicItem(response.data);
      })
  }

  function getCommentList(page) {
    if (!loading && hasNext) {
      setLoading(true)
      URLRequest.request(RequestType.DiscussTopicCommentList, 
        [id], 
        {"page": page, "size": STATICS.pageSize},
        function (response) {
          setCurrentPage(page)
          setHasNext(response.data.totalElements >= STATICS.pageSize*(page+1))
          if (page == 0) {
            setLiked(false)
            setCommentList(response.data.content);
          } else {
            setCommentList([...commentList, ...response.data.content]);
          }
          response.data.content.forEach(i => {
            if (i.liked) setLiked(true)
          })
          setLoading(false)
        },function (error) {
          setLoading(false)
        })
    }
  }

  function createEmptyListMsg() {
    return (
      <Box textAlign='center' alignContent='center'>  
        <Typography sx={{margin: 4}}>
          {FIXED_TEXT.emptyMsg}
        </Typography>
        <Box sx={{width: '20vh', height: '20vh', margin: '0 auto'}}>
          <img alt="empty" style={{width: '100%', height: '100%', objectFit: 'contain'}} src="/images/ic_empty_list_1.png" />
        </Box>
      </Box>)
  }

  function createListItem(item) {
    return (<ListItem
      sx={{border: '1px solid #fff', borderRadius: 2, padding: '8px 16px', margin: '0 0 16px'}}
      key={item.id}
      disableGutters>
      <Box sx={{width: '100%'}}>
        <Grid container>
          <Grid item xs={6} alignItems="center" display="flex" flexDirection="row">
            <Box style={{width: 16, height: 16}}>
              <img alt="icon" style={{width: '100%', height: '100%', objectFit: 'contain'}} src="/images/ic_profile_on.png" />
            </Box>
            <Typography fontSize={14} marginLeft={1}>{item.likeCount < 10 ? "***" : (item.creatorName) ? item.creatorName : item.createdBy}</Typography>
          </Grid>
          <Grid item xs={6}><Typography fontSize={14} color="#8b8b8b" align="right">{item.createdDate}</Typography></Grid>
        </Grid>
        <Typography marginTop={1} marginBottom={1} fontSize={18}>{item.comment}</Typography>

        <Button 
          onClick={() => handleItemLike(item)}
          style={{
            margin: '8px 0',
            color: item.liked ? '#ffffff' : '#1d1d1d',
            borderRadius: 100000,
            backgroundColor: item.liked ? '#20ace6' : '#8b8b8b',
            '&:hover': {
              backgroundColor: item.liked ? '#20ace6' : '#8b8b8b',
            },
          }}
          variant="contained">
          <img style={{width: '16px', 
              height: '16px', 
              objectFit: 'contain', 
              marginRight: (liked ? 8 : 0)}} 
              src={item.liked ? "/images/ic_like_on.png" : "/images/ic_like_off.png"} alt="like" />
          {liked ? <Typography fontSize={14} style={{height: '20px'}}>{item.likeCount}</Typography> : ""}
        </Button>
      </Box>
    </ListItem>)
  }

  function handleWriteComment() {
    navigate("/member/discuss/comment/"+id)
  }

  function handleItemLike(item) {
    if(!liked) {
      URLRequest.request(RequestType.DiscussTopicLikeComment, [item.id], {},
        function(response) {
          var copyList = [...commentList];
          copyList.forEach(function(i) {
            if (item.id === i.id) {
              i.liked = true;
              i.likeCount++;
              setLiked(true)
            }
          })
          setCommentList([...copyList])
        })
    } else {
      alert(FIXED_TEXT.doubleLike);
    }
  }

  function listenScrollEvent(event) {
    if ((event.target.scrollTop+event.target.clientHeight) >= (event.target.scrollHeight - 50)) {
      getCommentList(currentPage+1)
    } 
  }

  return (
    <>
      <MainHeader 
        navTitle={" "} 
        leftIconSrc={'/images/ic_back.png'} 
        leftIconAction={() => {navigate("/member/discuss")}} />
      <Box onScroll={listenScrollEvent}
        style={{flex: 1,
          padding: '8px 8px', 
          display:'flex', 
          height:'100%', 
          overflow: 'scroll',
          flexDirection: 'column', 
          justifyContent: 'flex-start'}}>
        <Box direction="column" justify="flex-start">
          <ListItemText 
            style={{margin: 0, padding: '16px'}}
            primaryTypographyProps={{ style: {fontSize: 20, marginBottom: 4} }}
            secondaryTypographyProps={{ style: {fontSize: 14, color: '#8B8B8B'} }}
            primary={`${topicItem.title}`} 
            secondary={`${FIXED_TEXT.discussEndTime}${topicItem.endDatetime}`} />  
        </Box>

        <Box 
          style={{ 
            padding: '0 16px', 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center' }}>
          {(commentList.length <= 0) ? (
            createEmptyListMsg()
          ) : (
            <List sx={{ width: '100%' }}>
            {commentList.map((item) => (
              createListItem(item)
            ))}
            </List>
          )}
        </Box>
      </Box>

      <Box style={{position: 'sticky', 
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '16px 24px',
          backgroundColor: '#1D1D1D', 
          bottom: 0}}
        >
        <ColorButton onClick={handleWriteComment} flex={1} sx={{padding: 1, fontSize: 16, textAlign: 'center'}}>
          {FIXED_TEXT.writeComment}
        </ColorButton>
      </Box>
    </>
  );
}

export default DiscussDetailPage;