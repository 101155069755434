import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate
} from "react-router-dom";
import RegisterView from "./pages/auth/RegisterView";
import LoginView from "./pages/auth/LoginView";
import HomeConfig from "./pages/Homepage";
import Layout from './common/Layout';
import { AuthProvider, useIsAuthenticated } from 'react-auth-kit'
import MainNavConfig from "./pages/MainNavPage";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AxiosAuthInterceptor } from "./network/URLRequest";
import ForgetPasswordConfig from "./pages/ForgetPassword";
import DialogProvider from "./pages/AlertDialog/AlertDialog.context.js";
import RefreshApi from "./network/RefreshApi"

const theme = createTheme({
  // palette: {
  //   mode: 'dark',
  //   primary: {
  //     main: '#FFFFFF',
  //   },
  //   secondary: {
  //     main : '#FFFFFF',
  //   },
  //   divider: {
  //     main : '#FFFFFF',
  //   },
  //   text: {
  //     main : '#FFFFFF',
  //   },
  // },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
          marginTop: 8,
          marginBottom: 8,
          input: {
            color: 'white'
          },
          '& label': {
            color: 'white',
          },
          '& label.Mui-focused': {
            color: 'white',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'white',
            },
            '&:hover fieldset': {
              borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'white',
            },
          },
        }
      }
    },
  }
});

export default function App() {
  const PrivateRoute = ({ Component }) => {
    const isAuthenticated = useIsAuthenticated();
    const auth = isAuthenticated();
    return auth ? <Component /> : <Navigate to="/login" />;
  };

  return (
    <AuthProvider 
      authType={'localstorage'}
      authName={'_auth'}
      cookieDomain={window.location.hostname}
      cookieSecure={window.location.protocol === "https:"}
      refresh={RefreshApi}>
      <ThemeProvider theme={theme}>
        <DialogProvider>
          <BrowserRouter>
            <AxiosAuthInterceptor>
              <Routes>
                <Route
                  exact
                  path="/"
                  element={<Navigate to="/member/discuss" replace />}
                />
                <Route
                  exact
                  path="/member"
                  element={<Navigate to="/member/discuss" replace />}
                />
                <Route path="/reg/:code" element={ <RegisterView /> }/>
                <Route path="/login" element={ <LoginView />} >
                  
                </Route>
                <Route path="/error" element={<CodeError />} />
                <Route path="/unauthorized" element={<UnauthorizedError />} />
                
                <Route element={<Layout />}>
                  <Route path="/home/*" element={<PrivateRoute Component={HomeConfig} />} />
                </Route>

                <Route>
                  <Route path="/member/*" element={<PrivateRoute Component={MainNavConfig} />} />
                </Route>

                <Route>
                  <Route path="/forgetPassword/*" element={<ForgetPasswordConfig />} />
                </Route>

                <Route path="*" element={<Navigate replace to="/" />} />
              </Routes>
            </AxiosAuthInterceptor>
          </BrowserRouter>
        </DialogProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

function CodeError() {
  return (
    <h1 style={{color: 'white'}}>Error Promotion Code</h1>
  );
}

function UnauthorizedError() {
  const navigate = useNavigate()
  setTimeout(function() {
    navigate('/login')
  }, 2000)

  return (
    <div style={{position: 'fixed',  inset: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <h1 style={{textAlign: 'center', margin: 'auto'}}>
        Token Expired, Redirecting to login....
      </h1>
    </div>
  )
}