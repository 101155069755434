import { Avatar, IconButton } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { EditProfilePageProvider } from "./EditProfilePage.context"
import EditProfileForm from "./components/EditProfileForm"
import EditAvatar from "./components/EditAvatar"
import MainHeader from "../../../common/MainHeader"
import Button from '@mui/material/Button';

const FIXED_TEXT = {
  editAccount: "修改帳戶"
}

function EditProfilePage() {
  let navigate = useNavigate();
  
  return <>
    <MainHeader navTitle={FIXED_TEXT.editAccount} 
        leftIconSrc={'/images/ic_back.png'} 
        leftIconAction={() => { navigate('/member/account') }} />
    <EditProfilePageProvider>
      <EditProfileForm/>
      <EditAvatar/>
    </EditProfilePageProvider>
  </>
}

export default EditProfilePage;