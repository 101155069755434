import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { Backdrop, CircularProgress, Box, Typography } from '@mui/material';

import MainHeader from '../../../common/MainHeader';
import { ColorButton } from '../../../common/ColorButton';
import URLRequest, { RequestType } from '../../../network/URLRequest';

const FIXED_TEXT = {
  forgetPassword: "忘記密碼",
  forgetPasswordMsg: "填寫已註冊電郵",
  email: "電郵",
  next: "下一步"
}

function ForgetRequestEmailPage() {
  const navigate = useNavigate();

  const [inputEmail, setInputEmail] = useState("");

  const [loading, setLoading] = React.useState(false);

  function handleForgetRequestEmailSuccess(response) {
    setLoading(false)
    navigate('/forgetPassword/verify');
  }

  function handleForgetRequestEmailFail(error) {
    setLoading(false)
  }

  function handleForgetRequestEmail() {
    setLoading(true)
    URLRequest.request(RequestType.AuthForgetPassword, [inputEmail], {},
      handleForgetRequestEmailSuccess, handleForgetRequestEmailFail)
    
  }

  return (
    <Box style={{
      height: '100%',
      display: 'flex', 
      flexDirection: 'column'}}>
      <MainHeader navTitle={FIXED_TEXT.forgetPassword} 
        leftIconSrc="/images/ic_back.png"
        leftIconAction={() => navigate("/login")} />

      <Box style={{margin: '24px', flex: 1}}>
        <Typography style={{margin : '12px 0'}}>
          {FIXED_TEXT.forgetPasswordMsg}
        </Typography>

        <TextField 
          id="inputEmail" value={inputEmail}
          onChange={text => setInputEmail(text.target.value)}
          label={FIXED_TEXT.email} type='tel' variant="outlined" />
      </Box>

      <Box style={{position: 'sticky', 
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: '16px 24px',
        backgroundColor: '#1D1D1D', 
        bottom: 0}}>
        <ColorButton 
          variant="outlined" 
          color="inherit"
          disabled={inputEmail === ''}
          onClick={() => handleForgetRequestEmail()} 
          sx={{flex: 1, 
            padding: 1, 
            fontSize: 16, 
            textAlign: 'center'}}>
          {FIXED_TEXT.next}
        </ColorButton>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}

export default ForgetRequestEmailPage;