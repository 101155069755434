import { Box, IconButton, List, ListItem, ListItemText } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"
import MainHeader from "../../../common/MainHeader"
import URLRequest, { RequestType } from "../../../network/URLRequest";
import { MainBottomNavBar } from "../../MainNavPage";

const FIXED_TEXT = {
  discuss: "討論",
  discussEndTime: "完結日期："
}

const STATICS = {
  pageSize: 20
}

function DiscussListPage() {
  let navigate = useNavigate();
  const [topicList, setTopicList] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(-1);
  const [loading, setLoading] = React.useState(false);
  const [hasNext, setHasNext] = React.useState(true);

  useEffect(() => {
    getTopicList(0)
  }, []);

  function getTopicList(page) {
    if (!loading && hasNext) {
      setLoading(true)
      URLRequest.request(RequestType.DiscussTopicPage, 
        [], 
        {"page": page, "size": STATICS.pageSize},
        function (response) {
          setCurrentPage(page)
          setHasNext(response.data.totalElements >= STATICS.pageSize*(page+1))
          if (page == 0) {
            setTopicList(response.data.content);
          } else {
            setTopicList([...topicList, ...response.data.content]);
          }
          setLoading(false)
        },function (error) {
          setLoading(false)
        })
    }
  }

  function toSiJaiPage() {
    navigate("/home/select");
  }

  function createListItem(item) {
    return (
      <ListItem
        onClick={() => handleItem(item)}
        sx={{border: '1px solid #fff', 
            borderRadius: 3, 
            padding: '8px 16px', 
            margin: '0 0 16px'}}
        key={item.id}
        disableGutters
      >
        <ListItemText secondaryTypographyProps={{ style: {color: '#8B8B8B'} }}
          primary={`${item.title}`} secondary={`${FIXED_TEXT.discussEndTime}${item.endDatetime}`} />
      </ListItem>
      )
  }
  function handleItem(item) {
    navigate('/member/discuss/detail/'+item.id)
  }

  function listenScrollEvent(event) {
    if ((event.target.scrollTop+event.target.clientHeight) >= (event.target.scrollHeight - 50)) {
      //user is at the end of the list so load more items
      getTopicList(currentPage+1)
    } 
  }
  
  return <>
    <MainHeader navTitle={FIXED_TEXT.discuss} />
    <Box onScroll={listenScrollEvent}
      style={{ height: '100%',
        overflow: 'scroll',
        padding: '16px', 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        marginBottom: '56px'}}>
      <List sx={{ width: '100%' }}>
        {topicList.map((item) => createListItem(item))}
      </List>
    </Box>
    <IconButton onClick={() => toSiJaiPage()} style={{ 
      position: 'absolute',
      right: '16px',
      bottom: '56px',
      height: '10vh',
      weight: '10vh',
    }}>
      <img style={{ 
        height: '100%',
        weight: '100%',
        objectFit: 'contain'
      }} src="/images/ic_sijai.png" alt="toSiJai" />
    </IconButton>
    <MainBottomNavBar />
  </>
}

export default DiscussListPage;