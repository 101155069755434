import React from 'react';
import {
  Route,
  Routes,
} from "react-router-dom";
import { AccountProvider } from './Account.context';
import ProfilePage from './ProfilePage';
import EditProfilePage from './EditProfilePage';


export const AccountConfig = () => {
  return (
    <Routes>
      <Route element={<AccountProvider />}>
        <Route index={true} element={<ProfilePage />}/>
        <Route path="/edit" element={<EditProfilePage />}/>
      </Route>
    </Routes>
  )
}
export default AccountConfig;