import axios from 'axios';
import moment from 'moment';
import { useEffect } from 'react';
import { useSignOut } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';
import { useAlertDialog } from '../pages/AlertDialog/AlertDialog.context';

const REQUEST_TIMEOUT = 5000
const instance = axios.create({})

const AxiosAuthInterceptor = ({ children }) => {
  const navigate = useNavigate();
  const signOut = useSignOut();
  const { openMessageDialog } = useAlertDialog();

  useEffect(() => {
      const resInterceptor = response => {
          return response;
      }
      const errInterceptor = error => {
          let response = error.response;
          let isLogin = (response.request.responseURL === fullPath(RequestType.AuthLogin))
          var message = 'Unknown Error';
          
          if (!isLogin && error.response.status === 401) {
            if (signOut()) {
              navigate('/unauthorized');
              return Promise.reject(error);
            } 
          } 

          if (error.response) {
            console.log("error.response.data.message.isArray()="+Array.isArray(error.response.data.message))
            message = (Array.isArray(error.response.data.message)) ? error.response.data.message.join('\n') : error.response.data.message
            if (error.response.data.error) message = error.response.data.error + ":" + message
          } else if (error.request) {
            message = JSON.stringify(error.request)
          } else {
            message = JSON.stringify(error.message)
          }
          openMessageDialog(message);
          return Promise.reject(error);
      }
      const interceptor = instance.interceptors.response.use(resInterceptor, errInterceptor);
      return () => instance.interceptors.response.eject(interceptor);
  }, [navigate])
  return children;
}

function fullPath(requestType, pathParams = []) {
  var string = process.env.REACT_APP_HKJC_CORE_SERVER + requestType
  if (requestType === RequestType.AuthLogin 
    || requestType === RequestType.AuthRefresh
    || requestType === RequestType.AuthRegister
    || requestType === RequestType.AuthValidate
    || requestType === RequestType.AuthPromoCodeValidate
    || requestType === RequestType.AuthMe
    || requestType === RequestType.AuthUpdateProfile
    || requestType === RequestType.AuthForgetPassword
    || requestType === RequestType.AuthForgetPasswordVerify
    || requestType === RequestType.AuthForgetPasswordUpdate) {
    string = process.env.REACT_APP_HKJC_AUTH_SERVER + requestType
  }
  if (pathParams !== null && pathParams.length > 0) {
    pathParams.forEach(element => {
      string = string.replace('{}', element);
    });
  }
  return string;
}

function httpMethod(requestType) {
  if (requestType === RequestType.AuthRefresh
    || requestType === RequestType.AuthPromoCodeValidate
    || requestType === RequestType.AuthMe
    || requestType === RequestType.AuthForgetPasswordVerify
    || requestType === RequestType.DiscussTopicPage
    || requestType === RequestType.DiscussTopicOne
    || requestType === RequestType.DiscussTopicCommentList
    || requestType === RequestType.EventGetList
    || requestType === RequestType.EventGetTicketList
    || requestType === RequestType.EventGetDetail) { 
      return "GET"; 
  } else if (requestType === RequestType.AuthUpdateProfile
    || requestType === RequestType.AuthForgetPasswordUpdate) { 
    return "PUT"; 
  }
  return "POST";
}

export { AxiosAuthInterceptor }
export const RequestType = {
  AuthLogin : "login", 
  AuthRefresh : "refresh", 
  AuthRegister : "register/{}",
  AuthValidate : "validate",
  AuthPromoCodeValidate : "validatePromotionCode/{}",
  AuthMe : "me",
  AuthGetProfileImage : "api/v1/identity/photo/{}",
  AuthForgetPassword : "forgetPassword?email={}",
  AuthForgetPasswordVerify : "forgetPassword/verify",
  AuthForgetPasswordUpdate : "forgetPassword/updatePassword",
  AuthUpdateProfileImage : "app/api/v1/account/identity/photo",
  AuthUpdateProfile : "updateMyProfile",
  DiscussTopicPage : "app/api/v1/topic/homepage", 
  DiscussTopicOne : "app/api/v1/topic/{}", 
  DiscussTopicCommentList : "app/api/v1/comment/homepage/{}", 
  DiscussTopicCreateComment : "app/api/v1/comment/create", 
  DiscussTopicLikeComment : "app/api/v1/comment/like/comment/{}", 
  EventGetList : "app/api/v1/event/homepage",  
  EventGetTicketList : "app/api/v1/event/ticket/page", 
  EventGetDetail: "app/api/v1/event/detail/{}",  
  EventPostJoin: "app/api/v1/event/join/{}?role={}",
  EventGetImage: "api/v1/event/photo/{}",
}

export default class URLRequest {
  static request(requestType, pathParams = [], queryParams = {}, responseCallback, errorCallback = () => {}) {    
    let method = httpMethod(requestType);
    let url = fullPath(requestType, pathParams);
    var headers = {}
    var params = {...queryParams}
    if (!(requestType  === RequestType.AuthLogin 
      || requestType === RequestType.AuthRefresh
      || requestType === RequestType.AuthRegister
      || requestType === RequestType.AuthValidate
      || requestType === RequestType.AuthPromoCodeValidate
      || requestType === RequestType.AuthForgetPassword
      || requestType === RequestType.AuthForgetPasswordVerify
      || requestType === RequestType.AuthForgetPasswordUpdate)) {
        headers = {...headers, 'Authorization' : localStorage.getItem('authHeader')}
    }

    if (requestType === RequestType.AuthRefresh) {
      headers = {...headers, 'Authorization': 'Bearer '+params.refreshToken}
      delete params.refreshToken
    }

    if (requestType === RequestType.AuthUpdateProfileImage) {
      headers = {...headers, 'Content-Type' : 'multipart/form-data'}
    }

    if (requestType === RequestType.AuthForgetPasswordVerify
      || requestType === RequestType.AuthForgetPasswordUpdate) {
      if (queryParams.forgetPasswordCode) {
        headers = {...headers, 'code': queryParams.forgetPasswordCode}
        delete params.forgetPasswordCode
      }
      if (queryParams.forgetPasswordToken) {
        headers = {...headers, 'token': queryParams.forgetPasswordToken}
        delete params.forgetPasswordToken
      }
    }

    if (method === "GET") {
      instance.get(url, {headers: headers, params: params}, { timeout: REQUEST_TIMEOUT })
      .then(function (response) {
        responseCallback(response);
      })
      .catch(function (error) {
        errorCallback(error);
      })
    } else if (method === "POST") {
      instance.post(url, params, { headers: headers, timeout: REQUEST_TIMEOUT })
      .then(function (response) {
        responseCallback(response);
      })
      .catch(function (error) {
        errorCallback(error);
      })

    } else if (method === "PUT") {
      instance.put(url, params, { headers: headers, timeout: REQUEST_TIMEOUT })
      .then(function (response) {
        responseCallback(response);
      })
      .catch(function (error) {
        errorCallback(error);
      })

    } else {
      // ***
    }
  }

  static simpleErrorHandler(error, openMessageDialog) {
    var message = 'Unknown Error';
    if (error.response) {
      message = error.response.data.message.join('\n')
    } else if (error.request) {
      message = JSON.stringify(error.request)
    } else {
      message = JSON.stringify(error.message)
    }
    openMessageDialog(message);
  }

  static eventImageUrl(id) {
    return fullPath(RequestType.EventGetImage, [id])
  }

  static memberImageUrl(memberId) {
    return fullPath(RequestType.AuthGetProfileImage, [memberId])+'?timestamp='+moment().valueOf()
  }
}