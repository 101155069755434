import { Box, Grid, List, ListItem, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from "react-router-dom"
import MainHeader from "../../../common/MainHeader"
import { MainBottomNavBar } from "../../MainNavPage";
import SwipeableViews from 'react-swipeable-views';
import { MaxidthImg } from "../../Homepage/common.style";
import IconLabel from "../../../common/IconLabel";
import URLRequest, { RequestType } from "../../../network/URLRequest";
import { eventDateTimeFormat } from "../../../common/Utils";
import moment from "moment";

const FIXED_TEXT = {
  event: "活動",
  eventAvailable: "舉行中",
  eventRecord: "已報名",
  eventStatusRegistered: "已報名",
  eventStatusAttended: "出席",
  eventStatusUnattended: "缺席",
  eventStatusVolunteer: "義工",
}

const STATICS = {
  pageSize: 20
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      width={'100%'}
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function EventTabPage() {
  let navigate = useNavigate();
  const {state} = useLocation();
  const [value, setValue] = React.useState((state != null && state.openTab) ? state.openTab : 0);
  const [eventList, setEventList] = React.useState([]);
  const [ticketList, setTicketList] = React.useState([]);

  const [currentPage, setCurrentPage] = React.useState(-1);
  const [loading, setLoading] = React.useState(false);
  const [hasNext, setHasNext] = React.useState(true);

  useEffect(() => {
    if(value === 0) {
      getEventList(0)
    } else {
      getTicketList(0)
    }
  }, []);

  function getEventList(page) {
    if (!loading && (hasNext || page === 0)) {
      setLoading(true)
      URLRequest.request(RequestType.EventGetList, 
        [], 
        {"page": page, "size": STATICS.pageSize},
        function (response) {
          setCurrentPage(page)
          setHasNext(response.data.totalElements >= STATICS.pageSize*(page+1))
          if (page == 0) {
            setEventList(response.data.content);
          } else {
            setEventList([...eventList, ...response.data.content]);
          }
          setLoading(false)
        })
    }
  }

  function getTicketList(page) {
    if (!loading && (hasNext || page === 0)) {
      setLoading(true)
      URLRequest.request(RequestType.EventGetTicketList, 
        [], 
        {"page": page, "size": STATICS.pageSize},
        function (response) {
          setCurrentPage(page)
          setHasNext(response.data.totalElements >= STATICS.pageSize*(page+1))
          if (page == 0) {
            setTicketList(response.data.content);
          } else {
            setTicketList([...ticketList, ...response.data.content]);
          }
          setLoading(false)
        })
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(newValue === 0) {
      getEventList(0)
    } else {
      getTicketList(0)
    }
  };

  const handleChangeIndex = (index) => {
    setValue(index);
    if(index === 0) {
      getEventList(0)
    } else {
      getTicketList(0)
    }
  };

  function handleItem(itemId) {
    navigate('/member/event/detail/'+itemId)
  }

  function listenScrollEvent(event) {
    if ((event.target.scrollTop+event.target.clientHeight) >= (event.target.scrollHeight - 50)) {
      if (value === 0) {
        getEventList(currentPage+1)
      } else {
        getTicketList(currentPage+1)
      }
    } 
  }

  function createOnLiveListItem(item) {
    return (<ListItem
      onClick={() => handleItem(item.id)}
      sx={{border: '1px solid white', 
        borderRadius: 3, 
        padding: '16px', 
        margin: '16px 0'}}
      key={item.id}
      disableGutters
    >
      <Grid container textAlign="left" spacing={2}>
        <Grid item xs={3}>
          <MaxidthImg style={{maxHeight: '90px', borderRadius: 3}} src={URLRequest.eventImageUrl(item.id)} alt={item.title} />
        </Grid>
        <Grid item xs={9}>
          <Typography style={{fontSize: 18}}>{item.title}</Typography>
          <IconLabel style={{marginTop: '8px'}} fontSize={14} iconSrc="/images/ic_event_date.png">
            {eventDateTimeFormat(item.registerEndDatetime)}
          </IconLabel>
          <IconLabel style={{marginTop: '8px'}} fontSize={14} iconSrc="/images/ic_event_location.png">
            {item.location}
          </IconLabel>
        </Grid>
      </Grid>
    </ListItem>)
  }

  function statusToDisplay(item) {
    var color = '#000000'
    var text = ''
    if(item.role === "VOLUNTEER") {
      color = '#74E2E2'
      text = FIXED_TEXT.eventStatusVolunteer
    } else if(item.absented) {
      color = '#FF3F00'
      text = FIXED_TEXT.eventStatusUnattended
    } else if(item.attended) {
      color = '#00D439'
      text = FIXED_TEXT.eventStatusAttended
    } else {
      color = '#8F74E2'
      text = FIXED_TEXT.eventStatusRegistered
    }
    return (
      <Typography style={{backgroundColor: color,
          padding: 4, 
          fontSize: '10pt',
          textAlign: 'center', 
          borderRadius: 100000}}>
        {text}
      </Typography>
    )
  }

  function createOnRegisteredListItem(item) {
    return (<ListItem
      onClick={() => handleItem(item.eventDTO.id)}
      sx={{border: '1px solid white', 
        borderRadius: 3, 
        padding: '16px', 
        margin: '16px 0'}}
      key={item.id}
      disableGutters
    >
      <Grid container textAlign="left" spacing={2}>
        <Grid item xs={3}>
          <MaxidthImg style={{maxHeight: '90px', borderRadius: 3}} src={URLRequest.eventImageUrl(item.eventDTO.id)} alt={item.eventDTO.title} />
        </Grid>
        <Grid item xs={6}>
          <Typography style={{fontSize: 18}}>{item.eventDTO.title}</Typography>
          <IconLabel style={{marginTop: '8px'}} fontSize={14} iconSrc="/images/ic_event_date.png">
            {eventDateTimeFormat(item.eventDTO.registerEndDatetime)}
          </IconLabel>
          <IconLabel style={{marginTop: '8px'}} fontSize={14} iconSrc="/images/ic_event_location.png">
            {item.eventDTO.location}
          </IconLabel>
        </Grid>
        <Grid item xs={3}>
          {statusToDisplay(item)}
        </Grid>
      </Grid>
    </ListItem>)
  }

  return <>
    <MainHeader navTitle={FIXED_TEXT.event} />
    <Box onScroll={listenScrollEvent} style={{height:'100%', overflow: 'scroll', marginBottom: '56px'}}>
      <Tabs
          variant="fullWidth" 
          value={value} 
          onChange={handleChange}
          TabIndicatorProps={{style: { background: 'white' }}}>
        <Tab style={{color: '#fff'}} label={FIXED_TEXT.eventAvailable} {...a11yProps(0)}/>
        <Tab style={{color: '#fff'}} label={FIXED_TEXT.eventRecord} {...a11yProps(1)}/>
      </Tabs>
      <Box sx={{borderTop: "1px solid white", height: '1px'}} />
      <SwipeableViews
        style={{bottom: '56px'}}
        axis={'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={'ltr'}>
          <List sx={{ padding: '16px' }}>
          {eventList.map((item) => createOnLiveListItem(item))}
          </List>
        </TabPanel>
        <TabPanel value={value} index={1} dir={'ltr'}>
          <List sx={{ padding: '16px'}}>
          {ticketList.map((item) => createOnRegisteredListItem(item))}
          </List>
        </TabPanel>
      </SwipeableViews>
    </Box>
    <MainBottomNavBar />
  </>
}

export default EventTabPage;