import { Box, Button, Dialog, DialogActions, DialogTitle, styled, Typography, Grid } from "@mui/material";
import { common } from "@mui/material/colors";
import moment from "moment";
import React, { useEffect } from "react";
import QRCode from "react-qr-code";
import { useNavigate, useParams } from "react-router-dom";
import IconLabel from "../../../common/IconLabel";
import MainHeader from "../../../common/MainHeader";
import { eventDateTimeFormat } from "../../../common/Utils";
import URLRequest, { RequestType } from "../../../network/URLRequest";
import { ColorButton, BlackColorButton, BlackOutlineButton } from "../../../common/ColorButton";

const FIXED_TEXT = {
  event: "活動",
  eventStatusVolunteer: "義工",
  eventStatusRegistered: "已報名",
  eventStatusAttended: "出席",
  eventStatusUnattended: "缺席",
  eventJoinedDate: "參與活動時間：",
  eventStartDate: "活動開始日期：",
  eventEndDate: "活動結束日期：",
  eventRegCount: "報名人數：",
  eventRegCountUnit: "人",
  eventRegEndDate: "報名截止日期：",
  eventDesc: "活動簡介",
  eventMustKnow: "活動須知",
  eventAsVolunteer: "成為義工",
  eventAsGuest: "參與活動",
  eventAttend: "簽到",
  eventJoinedDialogDate: "參與活動報名時間",
  cancel: "取消",
  confirm: "確定"
}

const dummyItem = {
  "createdBy": "d61eb2f0-5bc4-400c-aa0e-d9c8dae5d0e5",
  "createdDate": "2023-02-07 23:31:36",
  "lastModifiedBy": "d61eb2f0-5bc4-400c-aa0e-d9c8dae5d0e5",
  "lastModifiedDate": "2023-02-07 23:31:36",
  "id": 0,
  "topicId": 0,
  "commentId": 0,
  "quota": 0,
  "title": "hello world 222",
  "enabled": true,
  "description": "description",
  "remark": "remark",
  "location": "location",
  "registerStartDatetime": "2023-02-07 23:31:36",
  "registerEndDatetime": "2023-03-01 00:00:00",
  "eventStartDatetime": "2023-03-02 01:00:00",
  "eventEndDatetime": "2023-03-04 02:00:00",
  "topic": {
      "createdBy": "4f6a3f42-17ad-404f-ac16-c779b593caf4",
      "createdDate": "2023-02-07 16:04:21",
      "lastModifiedBy": "4f6a3f42-17ad-404f-ac16-c779b593caf4",
      "lastModifiedDate": "2023-02-07 16:04:21",
      "id": 2,
      "title": "2023-02-07 16:04:211",
      "enabled": true,
      "endDatetime": "2023-02-08 16:04:21",
      "commentCount": 0
  },
  "comment": {
      "createdBy": "2ac47e17-1bf9-4256-828c-8c7af423b4f1",
      "createdDate": "2023-02-07 16:04:21",
      "lastModifiedBy": "2ac47e17-1bf9-4256-828c-8c7af423b4f1",
      "lastModifiedDate": "2023-02-07 16:04:21",
      "id": 2,
      "topicId": 2,
      "comment": "99926fe7-d979-4b7d-a721-ad5d3dddf2ec",
      "enabled": true,
      "likeCount": 0
  },
  "ticketList": [
      {
          "createdBy": "184c0274-2e0a-424a-9014-48d244a6d6b6",
          "createdDate": "2023-02-08 02:00:27",
          "lastModifiedBy": "184c0274-2e0a-424a-9014-48d244a6d6b6",
          "lastModifiedDate": "2023-02-08 02:00:27",
          "id": "c03dddf9-c5a9-4c60-8671-40515ef9eb0f",
          "eventDTO": {
              "createdBy": "d61eb2f0-5bc4-400c-aa0e-d9c8dae5d0e5",
              "createdDate": "2023-02-07 23:31:36",
              "lastModifiedBy": "d61eb2f0-5bc4-400c-aa0e-d9c8dae5d0e5",
              "lastModifiedDate": "2023-02-07 23:31:36",
              "id": 2,
              "topicId": 2,
              "commentId": 2,
              "quota": 100,
              "title": "hello world 222",
              "enabled": true,
              "description": "description",
              "remark": "remark",
              "location": "location",
              "registerStartDatetime": "2023-02-07 23:31:36",
              "registerEndDatetime": "2023-03-01 00:00:00",
              "eventStartDatetime": "2023-03-02 01:00:00",
              "eventEndDatetime": "2023-03-04 02:00:00"
          },
          "memberId": "AAA",
          "role": "VOLUNTEER",
          "attended": true,
          "attendedDatetime": "2023-02-08 02:00:27"
      }
  ],
  "statistic": {
      "createdBy": "64451d22-2345-4462-8e2f-7a8f917a17df",
      "createdDate": "2023-02-07 23:31:36",
      "lastModifiedBy": "SYSTEM",
      "lastModifiedDate": "2023-02-07 23:31:36",
      "eventId": 2,
      "reservedCount": 0,
      "attendedCount": 0,
      "volunteerCount": 1
  }
}

function EventDetailPage() {
  let navigate = useNavigate();
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [attendOpen, setAttendOpen] = React.useState(false);
  const [volunteer, setVolunteer] = React.useState(false);
  const [eventItem, setEventItem] = React.useState(dummyItem)

  useEffect(() => {
    getEventDetail()
  }, []);

  function getEventDetail() {
    URLRequest.request(RequestType.EventGetDetail, 
      [id], 
      {},
      function (response) {
        setEventItem(response.data)
      })
  }

  function statusToDisplay(item) {
    var color = '#000000'
    var text = ''
    if(item.ticketList[0].role === "VOLUNTEER") {
      color = '#74E2E2'
      text = FIXED_TEXT.eventStatusVolunteer
    } else if(item.ticketList[0].absented) {
      color = '#FF3F00'
      text = FIXED_TEXT.eventStatusUnattended
    } else if(item.ticketList[0].attended) {
      color = '#00D439'
      text = FIXED_TEXT.eventStatusAttended
    } else {
      color = '#8F74E2'
      text = FIXED_TEXT.eventStatusRegistered
    }
    return (
      <Typography style={{backgroundColor: color,
          padding: '4px 36px', 
          fontSize: '10pt',
          textAlign: 'center', 
          borderRadius: 100000}}>
        {text}
      </Typography>
    )
  }

  function createCommentItem(item) {
    return (<Box
      sx={{border: '1px solid #fff', borderRadius: 2, padding: '8px 16px', margin: '0 0 16px'}}>
      <Box sx={{width: '100%'}}>
        <Grid container>
          <Grid item xs={6} alignItems="center" display="flex" flexDirection="row">
            <Box style={{width: 16, height: 16}}>
              <img alt="icon" style={{width: '100%', height: '100%', objectFit: 'contain'}} src="/images/ic_profile_on.png" />
            </Box>
            <Typography fontSize={14} marginLeft={1}>{(item.creatorName) ? item.creatorName : item.createdBy}</Typography>
          </Grid>
          <Grid item xs={6}><Typography fontSize={14} color="#8b8b8b" align="right">{item.createdDate}</Typography></Grid>
        </Grid>
        <Typography marginTop={1} marginBottom={1} fontSize={18}>{item.comment}</Typography>

        <Button 
          style={{
            margin: '8px 0',
            color: '#ffffff',
            borderRadius: 100000,
            backgroundColor: '#20ace6' ,
            '&:hover': {
              backgroundColor: '#20ace6' ,
            },
          }}
          variant="contained">
          <img style={{width: '16px', 
              height: '16px', 
              objectFit: 'contain', 
              marginRight: '8px'}} 
              alt="like"
              src={"/images/ic_like_on.png"} />
          <Typography fontSize={14} style={{height: '20px'}}>{item.likeCount}</Typography>
        </Button>
      </Box>
    </Box>)
  }

  const handleClickOpen = (isVol) => {
    setVolunteer(isVol);
    setOpen(true);
  };

  const handleClose = (confirm) => {
    setOpen(false);
    if(confirm) {
      var joinAs = "ATTENDANT"
      if (volunteer) {
        joinAs = "VOLUNTEER"
      }
      URLRequest.request(RequestType.EventPostJoin, [eventItem.id, joinAs], {}, 
        function(response) {
          navigate("/member/event", {
            state: {openTab: 1}
          })
        })
    }
  };

  const handleAttend = () => {
    setAttendOpen(true);
  }

  const handleAttendClose = () => {
    setAttendOpen(false);
  };

  return (
    <Box style={{flex: 1,
      display: 'flex', 
      flexDirection: 'column'}}>
      <MainHeader 
        navTitle={" "} 
        leftIconSrc={'/images/ic_back.png'} 
        leftIconAction={() => {navigate("/member/event")}} />
      <div style={{height: '40vh', overflow: 'hidden'}}>
        <img style={{width: '100%', 
            height: '100%', 
            objectFit: 'cover'}} 
          src={URLRequest.eventImageUrl(eventItem.id)} alt={eventItem.title}  />
      </div>
      <Box style={{margin: '16px 24px'}}>
        <Typography style={{ fontSize: 22 }}>{eventItem.title}</Typography>
        {(eventItem.ticketList.length > 0) ?
          (<><Box style={{margin: '16px 0', width: '100%', height: '1px', backgroundColor: '#fff'}} />
          <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <Typography>
              {FIXED_TEXT.eventJoinedDate + eventDateTimeFormat(eventItem.ticketList[0].createdDate)}
            </Typography>
            {statusToDisplay(eventItem)}
          </div>
          <Box style={{margin: '16px 0', width: '100%', height: '1px', backgroundColor: '#fff'}} /></>)
          : ""
        }
        <IconLabel style={{marginTop: '16px'}} iconSrc="/images/ic_event_date.png">
          {FIXED_TEXT.eventStartDate + eventDateTimeFormat(eventItem.eventStartDatetime)}
        </IconLabel>
        <IconLabel style={{marginTop: '8px'}} iconSrc="/images/ic_event_date.png">
          {FIXED_TEXT.eventEndDate + eventDateTimeFormat(eventItem.eventEndDatetime)}
        </IconLabel>
        <IconLabel style={{marginTop: '8px'}} iconSrc="/images/ic_event_location.png">
          {eventItem.location}
        </IconLabel>
        {((eventItem.quota != null) ? <IconLabel style={{marginTop: '8px'}} iconSrc="/images/ic_event_guest.png">
          {FIXED_TEXT.eventRegCount + eventItem.statistic.reservedCount + '／' + eventItem.quota + FIXED_TEXT.eventRegCountUnit}
        </IconLabel> : "")}
        <IconLabel style={{marginTop: '8px'}} iconSrc="/images/ic_event_time.png">
          {FIXED_TEXT.eventRegEndDate + eventDateTimeFormat(eventItem.registerEndDatetime)}
        </IconLabel>
        <Box style={{margin: '16px 0', width: '100%', height: '1px', backgroundColor: '#fff'}} />
        <Typography style={{lineHeight: '30px'}}>{FIXED_TEXT.eventDesc}</Typography>
        <Typography style={{color: '#8b8b8b', lineHeight: '30px'}}>{eventItem.description}</Typography>
        <Typography style={{marginTop: '16px', lineHeight: '30px'}}>{FIXED_TEXT.eventMustKnow}</Typography>
        <Typography style={{color: '#8b8b8b', lineHeight: '30px'}}>{eventItem.remark}</Typography>

        <Typography style={{marginTop: '32px', marginBottom: '8px', lineHeight: '30px'}}>{eventItem.topic.title}</Typography>
        {createCommentItem(eventItem.comment)}
      </Box>

      { 
      (eventItem.ticketList.length === 0) ? 
      <Box style={{position: 'sticky', 
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          padding: '16px 24px',
          backgroundColor: '#1D1D1D', 
          bottom: 0}}
        >
        <Button 
          variant="outlined" 
          color="inherit"
          onClick={() => handleClickOpen(true)} 
          sx={{flex: 1, 
            padding: 1, 
            fontSize: 16, 
            colorInherit: '#fff',
            textInherit: '#000',
            textAlign: 'center'}}>
          {FIXED_TEXT.eventAsVolunteer}
        </Button>
        <ColorButton 
          onClick={() => handleClickOpen(false)} 
          sx={{flex: 1, 
            marginLeft: 1, 
            padding: 1, 
            fontSize: 16, 
            textAlign: 'center'}}>
          {FIXED_TEXT.eventAsGuest}
        </ColorButton>
      </Box>
      : ((eventItem.ticketList[0].role === "VOLUNTEER") ? "" : 
      <Box style={{position: 'sticky', 
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          padding: '16px 24px',
          backgroundColor: '#1D1D1D', 
          bottom: 0}}
        >
        <ColorButton 
          variant="outlined" 
          color="inherit"
          onClick={() => handleAttend()} 
          sx={{flex: 1, 
            padding: 1, 
            fontSize: 16, 
            textAlign: 'center'}}>
          {FIXED_TEXT.eventAttend}
        </ColorButton>
      </Box>
        )
      }

      <Dialog
        open={open}
        onClick={() => handleClose(false)} 
        fullWidth={true}
        style={{padding: '32px'}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle 
          id="alert-dialog-title" 
          style={{fontSize: '20pt', paddingTop: '48px', textAlign: 'center'}}>
          {volunteer ? FIXED_TEXT.eventAsVolunteer : FIXED_TEXT.eventAsGuest}
        </DialogTitle>
        <DialogActions>
          <BlackOutlineButton 
            variant="outlined" 
            color="inherit"
            onClick={() => handleClose(false)} 
            sx={{flex: 1, 
              margin: "8px 8px 24px 24px", 
              padding: 1,
              fontSize: 16, 
              colorInherit: '#fff',
              textInherit: '#000',
              textAlign: 'center'}}>
            {FIXED_TEXT.cancel}
          </BlackOutlineButton>
          <BlackColorButton 
            onClick={() => handleClose(true)} 
            sx={{flex: 1, 
              margin: "8px 24px 24px 8px", 
              padding: 1,
              fontSize: 16, 
              color: '#fff',
              backgroundColor: '#000',
              textAlign: 'center'}}>
            {FIXED_TEXT.confirm}
          </BlackColorButton>
        </DialogActions>
      </Dialog>

      {(eventItem.ticketList.length > 0) ? 
      <Dialog
        open={attendOpen}
        onClick={() => handleAttendClose()} 
        fullWidth={true}
        style={{padding: '32px'}}
        aria-labelledby="alert-dialog-qr-title"
        aria-describedby="alert-dialog-qr-description"
      >
        <div style={{marginTop: '60px', marginBottom: '24px', width: '100%'}}>
          <QRCode 
            style={{marginLeft: '48px', height: "auto", maxWidth: "100%", width: "calc(100% - 96px)" }}
            value={eventItem.ticketList[0].id} />
        </div>
        <Typography
          style={{fontSize: '18pt', paddingBottom: '8px', textAlign: 'center'}}>
          {FIXED_TEXT.eventJoinedDialogDate}
        </Typography>
        <Typography
          style={{fontSize: '14pt', paddingBottom: '60px', textAlign: 'center'}}>
          {eventDateTimeFormat(eventItem.ticketList[0].createdDate)}
        </Typography>
      </Dialog> : ""}
    </Box>
  )
}

export default EventDetailPage;