import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Backdrop, CircularProgress, Box, Typography } from '@mui/material';

import MainHeader from '../../../common/MainHeader';
import { ColorButton } from '../../../common/ColorButton';
import ReactInputVerificationCode from 'react-input-verification-code';
import URLRequest, { RequestType } from '../../../network/URLRequest';
import { useForgetPassword } from '../ForgetPassword.context';

const FIXED_TEXT = {
  forgetPasswordVerify: "驗證帳戶",
  forgetPasswordVerifyMsg: "驗證碼已發送到你的註冊電郵",
  forgetPasswordVerifyMsgSecond: "請輸入",
  next: "下一步"
}

function ForgetRequestVerifyPage() {
  const navigate = useNavigate();
  const { setVerifyCode, setVerifyToken } = useForgetPassword();

  const [inputCode, setInputCode] = useState("");

  const [loading, setLoading] = React.useState(false);

  function handleForgetRequestVerifySuccess(response) {
    setLoading(false)
    if (response.data) {
      setVerifyCode(inputCode)
      setVerifyToken(response.data.token)
      navigate('/forgetPassword/set')
    } else {
      // something's wrong? 
      alert(JSON.stringify(response))
    }
  }

  function handleForgetRequestVerifyFail(error) {
    setLoading(false)
  }

  function handleForgetRequestVerify() {
    setLoading(true)
    
    URLRequest.request(RequestType.AuthForgetPasswordVerify, [], {forgetPasswordCode: inputCode},
      handleForgetRequestVerifySuccess, handleForgetRequestVerifyFail)
    
  }

  return (
    <Box style={{
      height: '100%',
      display: 'flex', 
      flexDirection: 'column'}}>
      <MainHeader navTitle={FIXED_TEXT.forgetPasswordVerify} 
        leftIconSrc="/images/ic_back.png" 
        leftIconAction={() => navigate("/forgetPassword")} />

      <Box style={{margin: '24px', flex: 1}}>
        <Typography style={{margin : '12px 0'}}>
          {FIXED_TEXT.forgetPasswordVerifyMsg}
        </Typography>
        <Typography style={{margin : '12px 0 32px'}}>
          {FIXED_TEXT.forgetPasswordVerifyMsgSecond}
        </Typography>

        <ReactInputVerificationCode placeholder="" length={6} onChange={setInputCode} />
      </Box>

      <Box style={{position: 'sticky', 
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: '16px 24px',
        backgroundColor: '#1D1D1D', 
        bottom: 0}}>
        <ColorButton 
          variant="outlined" 
          color="inherit"
          disabled={inputCode.length !== 6}
          onClick={() => handleForgetRequestVerify()} 
          sx={{flex: 1, 
            padding: 1, 
            fontSize: 16, 
            textAlign: 'center'}}>
          {FIXED_TEXT.next}
        </ColorButton>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}

export default ForgetRequestVerifyPage;