import React from 'react';
import { SelectSiJaiProvider } from "./components/SelectSiJai.context";
import Content from "./components/Content"

function SelectSiJai(){
  return (
    <SelectSiJaiProvider>
      <Content/>
    </SelectSiJaiProvider>
  )
}

export default SelectSiJai;