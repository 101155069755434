import { useNavigate, useParams } from "react-router-dom";
import MainHeader from "../../../common/MainHeader";
import { Box, Button, DialogActions, DialogContent, DialogTitle, styled, TextField } from "@mui/material";
import React from "react";
import URLRequest, { RequestType } from "../../../network/URLRequest";
import { BlackColorButton, BlackOutlineButton, ColorButton } from "../../../common/ColorButton";
import { useAlertDialog } from "../../AlertDialog/AlertDialog.context";

const FIXED_TEXT = {
  discussComment: "發表意見",
  discussCommentMsg: "你有甚麼想法？",
  discussCommentSubmitBtn: "發表",
  errorEmptyComment: "請輸入你的想法"
}

const CssTextField = styled(TextField)({
  height: '100%',
  marginTop: 8,
  marginBottom: 8,
  input: {
    color: 'white'
  },
  '& label': {
    color: 'white',
  },
  '& label.Mui-focused': {
    color: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiOutlinedInput-root': {
    color: 'white',
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
});

function DiscussWriteCommentPage() {
  let navigate = useNavigate();
  const {openMessageDialog} = useAlertDialog();
  let {id} = useParams();
  const [comment, setComment] = React.useState('');

  const handleTextInputChange = event => {
    setComment(event.target.value);
  };

  function handleCommentSubmit() {
    if (comment) {
      URLRequest.request(RequestType.DiscussTopicCreateComment,
        [],
        {
          "topicId": id,
          "comment": comment
        },
        function (response) {
          navigate("/member/discuss/detail/"+id);
        });
    } else {
      openMessageDialog(FIXED_TEXT.errorEmptyComment)
    }
  }

  return (<Box style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
    <MainHeader navTitle={FIXED_TEXT.discussComment} 
        leftIconSrc={'/images/ic_back.png'} 
        leftIconAction={() => {navigate("/member/discuss/detail/"+id)}}/>

    <Box style={{flex: 1, 
          padding: '16px 24px', 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'space-between'}}>
      <Box><h3>{FIXED_TEXT.discussCommentMsg}</h3></Box>
      <Box flex={1}>
        <CssTextField fullWidth multiline 
          rows={15} 
          value={comment}
          onChange={handleTextInputChange} />
      </Box>
    </Box>
    
    <Box style={{position: 'sticky', 
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '16px 24px',
          backgroundColor: '#1D1D1D', 
          bottom: 0}}
        >
      <ColorButton onClick={() => handleCommentSubmit()} flex={1} sx={{padding: 1, fontSize: 16, textAlign: 'center'}}>
        {FIXED_TEXT.discussCommentSubmitBtn}
      </ColorButton>
    </Box>
  </Box>)
}
export default DiscussWriteCommentPage;