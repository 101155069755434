import React from 'react';
import {
  Route,
  Routes,
} from "react-router-dom";
import { ForgetPasswordProvider } from './ForgetPassword.context';
import ForgetRequestEmailPage from './ForgetRequestEmailPage';
import ForgetRequestVerifyPage from './ForgetRequestVerifyPage';
import ForgetRequestSetPasswordPage from './ForgetRequestSetPasswordPage'


export const ForgetPasswordConfig = () => {
  return (
    <Routes>
      <Route element={<ForgetPasswordProvider />}>
        <Route index={true} element={<ForgetRequestEmailPage />} />
        <Route path='/verify' element={<ForgetRequestVerifyPage />} />
        <Route path='/set' element={<ForgetRequestSetPasswordPage />} />
      </Route>
    </Routes>
  )
}
export default ForgetPasswordConfig;