import { styled, Button } from '@mui/material';

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText('#fff'),
  backgroundColor: 'white',
  "&:disabled": {
    backgroundColor: 'grey',
    color: 'white'
  },
  '&:hover': {
    backgroundColor: 'white',
  },
}));

const BlackOutlineButton = styled(Button)(({ theme }) => ({
  color: 'black',
  borderColor: 'black',
  '&:hover': {
    borderColor: 'black',
  },
}));

const BlackColorButton = styled(Button)(({ theme }) => ({
  color: 'white',
  backgroundColor: 'black',
  '&:hover': {
    backgroundColor: 'black',
  },
}));

export { ColorButton, BlackColorButton, BlackOutlineButton};