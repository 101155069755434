import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';

function MainHeader(props){
  return(
    <Grid container 
        spacing={2} 
        textAlign="center" 
        alignItems="center" 
        style={{position: 'sticky', 
            margin: 0,
            padding: 0,
            minHeight: 56, 
            top:0, 
            left:0, 
            right: 0, 
            maxWidth: '100%',
            backgroundColor: "#1D1D1D", 
            zIndex: 1}}>
      <Grid item xs={2} style={{padding: 0, margin: '0 auto'}}>
        <Box sx={{width: (props.leftIconSrc ? 16 : 32), 
              height: (props.leftIconSrc ? 16 : 32), 
              margin: '0 auto'}}>
          <img style={{width: '100%', 
              height: '100%', 
              objectFit: 'contain'}} 
            onClick={props.leftIconAction} 
            src={props.leftIconSrc ? props.leftIconSrc : '/images/img_logo_white.png'}/>
        </Box>
      </Grid>
      <Grid item xs={8} style={{padding: 0, margin: '0 auto'}}>
        <span style={{fontSize: "1.5em"}}>{props.navTitle}</span>
      </Grid>
      <Grid item xs={2} style={{padding: 0, margin: '0 auto'}}>
        {props.rightIconSrc ? (
        <Box sx={{width: props.rightIconSize ? props.rightIconSize : 32, 
            height: props.rightIconSize ? props.rightIconSize : 32, 
            margin: '0 auto'}}>
          <img style={{width: '100%', 
              height: '100%', 
              objectFit: 'contain'}} 
            onClick={props.rightIconAction} 
            src={props.rightIconSrc}/>
        </Box>) : ("")}
      </Grid>
    </Grid>
  )   
}

export default MainHeader;