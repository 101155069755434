import { createContext, useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';

export const ForgetPasswordContext = createContext({
  verifyCode: '',
  setVerifyCode: () => {},
  verifyToken: '',
  setVerifyToken: () => {},
});

export const ForgetPasswordProvider = (props) => {
  const { children } = props;
  const [verifyCode, setVerifyCode] = useState('');
  const [verifyToken, setVerifyToken] = useState('');

  return (
    <ForgetPasswordContext.Provider
      value={{
        verifyCode,
        setVerifyCode,
        verifyToken,
        setVerifyToken
      }}
    >
      <Outlet />
    </ForgetPasswordContext.Provider>
  );
};

export const ForgetPasswordConsumer = ForgetPasswordContext.Consumer;
export const useForgetPassword = () => useContext(ForgetPasswordContext);