import { Avatar, IconButton, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import MainHeader from "../../../common/MainHeader"
import EditIcon from '@mui/icons-material/Edit';
import { MainBottomNavBar } from "../../MainNavPage";
import { useSignOut } from "react-auth-kit";
import React, { useEffect, useRef } from "react";
import URLRequest, { RequestType } from "../../../network/URLRequest";

const FIXED_TEXT = {
  account: "帳戶"
}

function ProfilePage() {
  let navigate = useNavigate();
  var isFirst = true;
  const signOut = useSignOut()

  const [profile, setProfile] = React.useState({
    "id":"",
    "displayName":"",
    "phone":"",
    "instagram":"",
    "email":"",
    "promotionCode":"",
    "enabled":true})

  useEffect(() => {
    getProfile()
  }, [])

  function getProfile() {
    if(isFirst) {
      isFirst = false
      URLRequest.request(RequestType.AuthMe, [], {},
        function (response) {
          setProfile(response.data)
        })
    }
  }

  function handleEdit() {
    navigate('/member/account/edit')
  }
  function handleLogout() {
    if(signOut()){
      localStorage.removeItem('authHeader')
      navigate('/login')
    }
  }

  return <>
    <MainHeader navTitle={FIXED_TEXT.account} 
      rightIconSrc="/images/ic_logout.png" 
      rightIconSize={24}
      rightIconAction={handleLogout} 
    />
    <div style={{padding: '10vh', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <div style={{width: '20vh', height: '20vh', display: 'flex', justifyContent: 'center', position: 'relative'}}>
        <Avatar style={{width: '100%', height: '100%'}}  srcSet={URLRequest.memberImageUrl(profile.id)} />
        <IconButton onClick={() => handleEdit()} 
          style={{padding: 8, 
            width: '20%', 
            height: '20%', 
            backgroundColor: '#8B8B8B', 
            color:'#fff', 
            position: 'absolute', 
            bottom: 0, 
            right: 0 }}>
          <EditIcon style={{width: '20px'}} />
        </IconButton>
      </div>
      <Typography style={{
        marginTop: '16px',
        fontSize: '18pt'
      }}>
        {profile.displayName}
      </Typography>
      <Typography style={{
        marginTop: '8px',
        fontSize: '16pt'
      }}>
        {profile.instagram}
      </Typography>
      <Typography style={{
        marginTop: '8px',
        fontSize: '16pt'
      }}>
        {profile.email}
      </Typography>
    </div>
    <MainBottomNavBar />
  </>
}

export default ProfilePage;