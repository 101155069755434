import React from 'react';
import Header from "./Header";
import { Outlet } from "react-router-dom";
function Layout(props){

 return(
    <div style={{minHeight: "100vh", backgroundColor: "#ffffff", padding: '20px'}}>
      <Header />
      <Outlet/>
    </div>
 )   
}

export default Layout;