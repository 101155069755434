import { useEffect, useState, useRef, createRef } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { useHome } from '../../Home.context';
import { MaxidthImg, SelectCharacterBtn, NextBtn, InstructionText } from '../../common.style';

function Content(props) {
  const text = {
    pleaseSelect: '請選擇你喜愛的匙仔',
    select: '這個!',
  }
  const [imgPath, setImgPath] = useState('/images/img_character_1.png');
  const { activeIndex, setActiveIndexToLocalStorage } = useHome();

  const getSelectCharacterImg = (index) => {
    const prefix = '/images/ic_select_character';
    const postfix = '.png';
    if(index === activeIndex){
      return prefix + "_" + index + "_on" + postfix;
    } else {
      return prefix + "_" + index + "_off" + postfix;
    }
  }
  const setActiveCharacter = (index) => {
    const prefix = '/images/img_character';
    const postfix = '.png';
    setActiveIndexToLocalStorage(index);
    setImgPath(prefix + "_" + index + postfix);
  }

  useEffect(() => {
    setActiveCharacter(activeIndex);
  }, [activeIndex]);

  return (
    <>
    <Grid container direction='column' justifyContent='center' alignItems='center'>
      <Grid item xs={6}>
        <InstructionText>{text.pleaseSelect}</InstructionText></Grid>

      <Grid container style={{marginTop: '20px', maxWidth: '60vw', height: '50vh'}} justifyContent='center' alignItems='flex-end'>
        <MaxidthImg src={imgPath}/>
      </Grid>

      <Grid container item xs={8} style={{marginTop: '20px'}} justifyContent='center' alignItems='flex-end'>
        <SelectCharacterBtn onClick={() => setActiveCharacter(1)}><MaxidthImg src={getSelectCharacterImg(1)}/></SelectCharacterBtn>
        <SelectCharacterBtn onClick={() => setActiveCharacter(2)}><MaxidthImg src={getSelectCharacterImg(2)}/></SelectCharacterBtn>
        <SelectCharacterBtn onClick={() => setActiveCharacter(3)}><MaxidthImg src={getSelectCharacterImg(3)}/></SelectCharacterBtn>
        <SelectCharacterBtn onClick={() => setActiveCharacter(4)}><MaxidthImg src={getSelectCharacterImg(4)}/></SelectCharacterBtn>
        <SelectCharacterBtn onClick={() => setActiveCharacter(5)}><MaxidthImg src={getSelectCharacterImg(5)}/></SelectCharacterBtn>
      </Grid>

      <Grid item xs={3} style={{marginTop: '20px'}}>
        <NextBtn href='/home/demo' variant='contained'>{text.select}</NextBtn>
      </Grid>
    </Grid>
    </>
  )
}

export default Content;