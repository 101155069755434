import { createContext, useState, useContext } from 'react';

export const SiJaiDemoContext = createContext({
    
});

export const SiJaiDemoProvider = (props) => {
  const { children } = props;
  

  return (
    <SiJaiDemoContext.Provider
      value={{
        
      }}
    >
      {children}
    </SiJaiDemoContext.Provider>
  );
};


export const SiJaiDemoConsumer = SiJaiDemoContext.Consumer;
export const useSiJaiDemo = () => useContext(SiJaiDemoContext);