import { createContext, useState, useContext, useRef } from 'react';

export const EditProfilePageontext = createContext({
  isEditAvatarOpen: false,
  setIsEditAvatarOpen: () => {},
  saveBtnHeight: 0,
  setSaveBtnHeight: () => {},
  fileUpdateRef: null,
  // AvatarUpdateRef: null,
});

export const EditProfilePageProvider = (props) => {
  const { children } = props;
  const [isEditAvatarOpen, setIsEditAvatarOpen] = useState(false);
  const [saveBtnHeight, setSaveBtnHeight] = useState(0);
  const fileUpdateRef = useRef(null);

  return (
    <EditProfilePageontext.Provider
      value={{
        isEditAvatarOpen,
        setIsEditAvatarOpen,
        saveBtnHeight,
        setSaveBtnHeight,
        fileUpdateRef,
      }}
    >
      {children}
    </EditProfilePageontext.Provider>
  );
};

export const EditProfilePageonsumer = EditProfilePageontext.Consumer;
export const useEditProfilePage = () => useContext(EditProfilePageontext);