import React from "react";
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Link, Outlet, Route, Routes } from "react-router-dom";
import AccountConfig from "../Account";
import DiscussConfig from "../Discuss";
import EventConfig from "../Event";


export const MainBottomNavBar = () => {
  var nowTab = 0
  if(window.location.href.indexOf("/member/discuss") > 0) {
    nowTab = 0
  } else if(window.location.href.indexOf("/member/event") > 0) {
    nowTab = 1
  } else if(window.location.href.indexOf("/member/account") > 0) {
    nowTab = 2
  }
  const [value, setValue] = React.useState(nowTab);

  return (
    <BottomNavigation
      sx={{
        position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: '#1D1D1D', 
        "& .MuiBottomNavigationAction-root, .Mui-selected, svg": {
          color: "#8B8B8B"
        },
        "& .Mui-selected, .Mui-selected > svg": {
          color: "#fff"
        }
      }}
      showLabels
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
    >
      <BottomNavigationAction component={Link} label="" to="/member/discuss/" icon={<img alt="Discuss" style={{height: '40%', objectFit: 'contain'}} src={value === 0 ? "/images/ic_discuss_on.png":"/images/ic_discuss_off.png"} />} />
      <BottomNavigationAction component={Link} label="" to="/member/event/"  icon={<img alt="Event" style={{height: '40%', objectFit: 'contain'}} src={value === 1 ? "/images/ic_event_on.png":"/images/ic_event_off.png"} />} />
      <BottomNavigationAction component={Link} label="" to="/member/account/" icon={<img alt="Profile" style={{height: '40%', objectFit: 'contain'}} src={value === 2 ? "/images/ic_profile_on.png":"/images/ic_profile_off.png"} />} />
    </BottomNavigation>
  );
}

export const MainNavPage = () => {
  return (
    <Box height="100vh" display="flex" flexDirection="column" backgroundColor="#000">
      <Box display={'flex'} flex={1} flexDirection="column" overflow="auto">
        <Outlet />
      </Box>
    </Box>
  );
}

export const MainNavConfig = () => {
  return (
    <Routes>
      <Route element={ <MainNavPage /> }>
        <Route path="/discuss/*" element={<DiscussConfig />}/>
        <Route path="/event/*" element={<EventConfig />}/>
        <Route path="/account/*" element={<AccountConfig />}/>
      </Route>
    </Routes>
  )
}
export default MainNavConfig;