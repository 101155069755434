import { Box, Typography } from '@mui/material';

function IconLabel(props){
  return (
    <Box style={{display: 'flex', alignItems: 'center', ...props.style}}>
      <img style={{
        height: '16px',
        width: '16px',
        objectFit: 'contain'
      }} src={props.iconSrc} />
      <Typography style={{fontSize: props.fontSize, flex: 1, marginLeft: '12px'}}>{props.children}</Typography>
    </Box>
  )
}

export default IconLabel;