import { createContext, useContext } from 'react';
import { Outlet } from "react-router-dom";

export const EventContext = createContext({

});

export const EventProvider = (props) => {
  const { children } = props;

  return (
    <EventContext.Provider
      value={{

      }}
    >
      <Outlet/>
    </EventContext.Provider>
  );
};

export const EventConsumer = EventContext.Consumer;
export const useEvent = () => useContext(EventContext);