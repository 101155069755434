import { useEffect, useState, useRef, createRef } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Avatar, IconButton, TextField } from "@mui/material"
import { useEditProfilePage } from '../EditProfilePage.context'
import URLRequest, { RequestType } from '../../../../network/URLRequest';
import { useNavigate } from 'react-router-dom';

const TEXT = {
  name: '姓名',
  igName: 'IG名稱',
  email: '電郵',
  save: '儲存',
}

const SubmitBtn = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    backgroundColor: 'white',
    color: 'black',
    padding: '10px 30px',
    width: '100%',
    '&:hover': {
      backgroundColor: 'white',
    }
  },
}));
function EditProfileForm(props) {
  const { setIsEditAvatarOpen, setSaveBtnHeight, fileUpdateRef } = useEditProfilePage();
  const saveBtnRef = useRef(null);
  const navigate = useNavigate();

  const [from, setFrom] = useState({
    avatar: '',
    avatarFile: '',
    name: '',
    igName: '',
    email: '',
  });

  useEffect(() => {
    setSaveBtnHeight(saveBtnRef.current.offsetTop - 50);
    // TODO: Get data from backend
    getProfile()
    
  }, []);

  const getProfile = () => {
    // if(isFirst) {
    //   isFirst = false
      URLRequest.request(RequestType.AuthMe, [], {},
        function (response) {
          let profile = response.data
          setFrom({
            avatarFile: '',
            avatar: URLRequest.memberImageUrl(profile.id),
            name: profile.displayName,
            igName: profile.instagram,
            email: profile.email
          })
        })
    // }
  }


  const handleClickAvatar = () => {
    setIsEditAvatarOpen(true);
  }

  const handleSubmit = () => {
    console.log(from);
    //TODO: Submit from
    URLRequest.request(RequestType.AuthUpdateProfile, [], {
      instagram: from.igName,
      displayName: from.name,
      email: from.email
    }, function () {
      if (from.avatarFile !== '') {
        handleUploadAvatarFile()
      } else {
        navigate('/member/account')
      }
    })
  }

  const handleUploadAvatarFile = () => {
    URLRequest.request(RequestType.AuthUpdateProfileImage, [], {
      photo: from.avatarFile
    }, function () {
      navigate('/member/account')  
    })
  }

  const handleUploadAvatar = (e) => {
    const file = e.target.files[0];
    console.log(file);
    // Assign file to from.avatar
    setFrom({
      ...from,
      avatar: URL.createObjectURL(file),
      avatarFile: file
    })

    setIsEditAvatarOpen(false);
  }

  return (
    <div style={{padding: '0px 20px'}}>
      <div style={{padding: '10vh', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <div style={{width: '20vh', height: '20vh', display: 'flex', justifyContent: 'center'}}>
          <Button style={{width: '100%', height: '100%'}} type='text' onClick={() => handleClickAvatar()}>
            <Avatar style={{width: '100%', height: '100%'}} src={from.avatar} />
          </Button>
        </div>
      </div>

      <div>
        <TextField
          variant="outlined" 
          id="name" 
          value={from.name}
          label={TEXT.name} 
          onChange={value => setFrom({...from, name: value.target.value})}
        />
      </div>

      <div>
        <TextField
          variant="outlined" 
          id="igName" 
          value={from.igName}
          label={TEXT.igName} 
          onChange={value => setFrom({...from, igName: value.target.value})}
        />
      </div>

      <div>
        <TextField
          variant="outlined" 
          id="email" 
          type="email"
          value={from.email}
          label={TEXT.email} 
          onChange={value => setFrom({...from, email: value.target.value})}
        />
      </div>
      <input type='file' accept="image/*" ref={fileUpdateRef} onChange={(e) => handleUploadAvatar(e)} style={{display: 'none'}}/>
      <div ref={saveBtnRef} style={{marginTop: '20px'}}>
        <SubmitBtn onClick={() => handleSubmit()}>{TEXT.save}</SubmitBtn>
      </div>
    </div>
  )
}

export default EditProfileForm;