import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const MaxidthImg = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    maxWidth: '100%',
    maxHeight: '100%',
    border: 'none',
  },
}));

export const SelectCharacterBtn = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
     width: '15%',
     padding: '5px',
     minWidth: '0px',
     margin: '0 5px',
  },
}));

export const NextBtn = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    border: 'solid 1px #000',
    borderRadius: '100%',
    backgroundColor: '#000',
    height: '90px',
    width: '90px',
    fontSize: '20px',
    fontWeight: '700',
  },
}));

export const InstructionText = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    maxWidth: '100%',
    textAlign: 'center',
    fontSize: '20px',
    color: '#000',
  },
}));