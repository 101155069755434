import React from 'react';
import {
  Route,
  Routes,
} from "react-router-dom";
import { EventProvider } from './Event.context';
import EventDetailPage from './EventDetailPage';
import EventTabPage from './EventTabPage';

export const EventConfig = () => {
  return (
    <Routes>
      <Route element={<EventProvider />}>
        <Route index={true} element={<EventTabPage />} />
        <Route path='/detail/:id' element={<EventDetailPage />} />
      </Route>
    </Routes>
  )
}
export default EventConfig;