import { useEffect, useState, useRef, createRef } from 'react';
import Backdrop from '@mui/material/Backdrop';
import { styled } from '@mui/material/styles';
import { useEditProfilePage } from '../EditProfilePage.context'
import Button from '@mui/material/Button';

const TEXT = {
  capturePhoto: '拍攝照片',
  uploadPhoto: '上載或拍攝相片',
}

const UploadBtn = styled(Button)(({ theme }) => ({
  // [theme.breakpoints.down('md')]: {
    backgroundColor: 'white', 
    color: 'black', 
    padding: '10px 40px', 
    margin: '0px 15px',
    width: '80%',
    '&:hover': {
      backgroundColor: 'white',
    }
  // },
}));

function EditAvatar(props) {
  const { isEditAvatarOpen, setIsEditAvatarOpen, saveBtnHeight, fileUpdateRef } = useEditProfilePage();

  useEffect(() => {
  }, [saveBtnHeight]);

  const handleClose = (e) => {
    e.preventDefault();
    if (e.target !== e.currentTarget) {
      return;
    }
    
    setIsEditAvatarOpen(false);
  }

  const handleUploadAvatar = (e) => {
    console.log(e);
  }
  return (
    <>
    <Backdrop
      sx={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isEditAvatarOpen}
      onClick={(e) => handleClose(e)}
    >
      <div style={{
        backgroundColor: 'black', 
        minHeight: '25vh', 
        marginTop: saveBtnHeight, 
        width: '100%', 
        
      }}>
        <div style={{width: '100%', display: 'flex', justifyContent: 'center' }}>        
          <UploadBtn variant="contained" onClick={() => {fileUpdateRef.current.click()}}>上載相片</UploadBtn>   
        </div>
      </div>
    </Backdrop>
    </>
  )
}

export default EditAvatar;